import { format } from "date-fns";
import { ChipColorKeys, isDeepEmpty } from "@epcnetwork/core-ui-kit";

import { SuppressionModel } from "models";
import { QueryParams, FiltersType, AppearanceMap } from "./suppression-list.types";

export const tableColumns = [
  { label: "Value", queryKey: "value" },
  { label: "Category", queryKey: "category" },
  { label: "Esp", queryKey: "esp" },
  { label: "List type", queryKey: "listType" },
  { label: "Kind", queryKey: "isGlobal" },
  { label: "Status", queryKey: "cleaningServiceStatus" },
  { label: "" },
];

export const suppressionKindOptions = [
  {
    label: "Global",
    value: "true",
  },
  {
    label: "Local",
    value: "false",
  },
];

export const queryKeys = tableColumns.map(({ queryKey }) => `order[${queryKey}]`);
export const nonClearableKeys: string[] | Array<keyof FiltersType> = ["value"].concat(queryKeys);

export const initialQueryParams: QueryParams = {
  limit: 10,
  offset: 0,
};

const chipAppearance: AppearanceMap = {
  Valid: "success",
  Bounce: "warning",
  Invalid: "error",
  "Catch all": "primary",
  "Not running": "primary",
};

export const getCategories = (category: SuppressionModel["category"]): string => {
  if (isDeepEmpty(category) || !category.length) return "";
  return category.map((cat) => cat.name).join("; ");
};

export const getEsp = (esp: SuppressionModel["esp"]): string => {
  if (isDeepEmpty(esp) || !esp.length) return "";
  return esp.map((cat) => cat.name).join("; ");
};

export const getListType = (listType: SuppressionModel["listType"]): string => {
  if (isDeepEmpty(listType) || !listType.length) return "";
  return listType.map((cat) => cat.name).join("; ");
};

export const getSuppressionListKind = (isGlobal: boolean | undefined): string => {
  return isGlobal ? "Global" : "Local";
};

export const getSuppressionKind = (listType: SuppressionModel["listType"]): string => {
  if (isDeepEmpty(listType) || !listType.length) return "";

  const kind = listType.map((list) => getSuppressionListKind(list.isGlobal));
  return [...new Set(kind)].join("; ");
};

export const getStatus = (
  cleaningServiceStatus: SuppressionModel["cleaningServiceStatus"],
): string => {
  return cleaningServiceStatus?.name || "Not running";
};

export const getStatusAppearance = (
  cleaningServiceStatus: SuppressionModel["cleaningServiceStatus"],
): ChipColorKeys => {
  const status = getStatus(cleaningServiceStatus);
  return chipAppearance[status] ?? "primary";
};

export const getFileName = (): string => {
  const date = new Date();
  const formattedDate = format(date, "MM-dd-yyyy-HH-mm-s");
  return `suppression-${formattedDate}`;
};
