import { object, string, array } from "yup";

import { selectMultipleValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";

export const columns = [
  { value: "email", label: "Value" },
  { value: "category", label: "Category" },
  { value: "esp", label: "Esp" },
  { value: "listType", label: "List type" },
  { value: "cleaningServiceStatus", label: "Status" },
  { value: "userGroup", label: "Group" },
  { value: "md5", label: "MD5" },
];

export const validationSchema = object().shape({
  filename: string().min(10, "File Name should have more 10 symbols").required(requiredFieldText),
  columns: selectMultipleValidation(),
  filters: array().of(string()),
});
