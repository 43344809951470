import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Button,
  Modal,
  MessageField,
  Form,
  FormButtons,
  useFileParser,
} from "@epcnetwork/core-ui-kit";

import { getSelectedColumns } from "utils";
import { FormField } from "components";
import { CutFileItem, Values } from "../cut.types";
import { validationSchema } from "../cut.constants";

type CutModalFormProps = {
  file: CutFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: CutFileItem) => void;
};

const CutModalForm: FC<CutModalFormProps> = ({ file, setClose, onSubmitClick }) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<Values>({ headerValues: file.data });

  const onSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.headerValues,
        columns: getSelectedColumns(values.headerValues.headers),
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        <FormField
          type="file-table"
          name="headerValues"
          label="Select Email column"
          parsedFile={parsedFile}
          loading={loading}
          selector="checkbox"
          showHeadersSwitch={false}
        />
        <FormButtons>
          <Button appearance="secondary" onClick={setClose}>
            Cancel
          </Button>
          <Button type="submit">Set values</Button>
        </FormButtons>
      </Form>
    </Modal>
  );
};

export { CutModalForm };
