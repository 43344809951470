import { FC, memo, useState, CSSProperties } from "react";
import cn from "classnames";
import { useWindowSize, NotAuthorized, useLocalStorage } from "@epcnetwork/core-ui-kit";

import { entityName, collapsedDesktop, sidebarWidth } from "../sidebar/sidebar.constants";
import { Sidebar } from "../sidebar/sidebar";
import { Navbar } from "../navbar/navbar";

import styles from "./navigation.module.css";

interface Props {
  component: FC<unknown>;
  showNavigation: boolean;
  isAuthorized?: boolean;
}

const Navigation = memo(({ component, showNavigation, isAuthorized = true, ...rest }: Props) => {
  const Component = component;
  const { height } = useWindowSize();
  const { value } = useLocalStorage(entityName);

  const [isMenuClosed, setIsMenuClosed] = useState<boolean>(value || false);

  const toggleSidebar = () => {
    setIsMenuClosed((prev) => !prev);
  };

  const width = value ? collapsedDesktop : sidebarWidth;
  const contentStyles = cn(styles.content, { [styles.closed]: value });

  const inlineStyles: CSSProperties = {
    maxWidth: `calc(100vw - ${width})`,
    height,
  };

  if (!isAuthorized) {
    return (
      <NotAuthorized title="Access Denied" subtitle="You're not authorized to view this page" />
    );
  }

  if (!showNavigation) {
    return <Component {...rest} />;
  }

  return (
    <div className={styles.container} data-testid="app">
      <Sidebar data-testid="sidebar" collapsed={isMenuClosed} />
      <div className={contentStyles} data-testid="content" style={inlineStyles}>
        <Navbar toggleSidebar={toggleSidebar} isSidebarOpen={isMenuClosed} />
        <Component {...rest} />
      </div>
    </div>
  );
});

export { Navigation };
