import ReactDOM from "react-dom";

import { Providers } from "components";
import * as serviceWorker from "./serviceWorker";
import { App } from "./app";

import "assets/styles/transitions.css";
import "assets/styles/theme.css";
import "assets/styles/index.css";
import "@epcnetwork/core-ui-kit/dist/assets/styles/index.css";

require("codemirror/lib/codemirror.css");
require("codemirror/mode/javascript/javascript");

ReactDOM.render(
  <Providers>
    <div className="theme">
      <App />
    </div>
  </Providers>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
