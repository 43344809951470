import { array, boolean, object, SchemaOf, string } from "yup";

import {
  emailColumnValidation,
  headersValidation,
  sanitizeFileName,
  selectMultipleValidation,
  suppressionDatabaseValidation,
} from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat, xlsxFormat } from "constants/file.constants";
import { Values, MatchFileData } from "./match.types";

export const supportedFormats = [csvFormat, xlsxFormat, txtFormat].flat();
export const validationSchema: SchemaOf<Values> = object({
  headerValues: object({
    email: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
  databases: selectMultipleValidation(),
  listTypes: suppressionDatabaseValidation(),
  isGlobal: boolean(),
  categories: array().of(string()).notRequired(),
});

export const getInitialData = (item: File): MatchFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  email: { key: "", index: -1 },
  databases: [],
});
