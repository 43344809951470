import { FC, ReactNode } from "react";
import { Search, SearchProps, useFilters } from "@epcnetwork/core-ui-kit";

import styles from "./toolbar.module.css";

type ToolbarProps = Omit<SearchProps, "setSearch"> & {
  filters?: ReactNode;
  setSearch: ReturnType<typeof useFilters>["setSearch"];
};

const Toolbar: FC<ToolbarProps> = ({ filters, children, setSearch, ...rest }) => {
  const handleSearch: SearchProps["setSearch"] = (value) => {
    setSearch(value);
  };

  return (
    <div className={styles.toolbar}>
      <Search width="small" setSearch={handleSearch} {...rest} className={styles.search} />
      {filters}
      <div className={styles.spacer} />
      {children}
    </div>
  );
};

export { Toolbar };
