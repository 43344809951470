import { useSelector } from "react-redux";
import { FC, useState } from "react";
import cn from "classnames";
import {
  Button,
  Form,
  FormField,
  MessageField,
  notification,
  useFormValues,
  useSubmit,
} from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { ADMIN_PERMISSION } from "constants/roles.constants";
import { getSuppressionListTypeById, postSuppressionSettings, putSuppressionSettings } from "api";
import { SuppressionSettingsFormProps } from "./suppression-settings-form.types";
import { initialValues, validationSchema } from "./suppression-settings-form.constants";

import styles from "./suppression-settings-form.module.css";

const SuppressionSettingsFormPage: FC<SuppressionSettingsFormProps> = ({
  closeModal,
  handleItemChange,
  editId,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const [error, setError] = useState("");

  const { formProps, mapInitialValues } = useFormValues(
    initialValues,
    getSuppressionListTypeById.setParams({ id: editId }),
    !!editId,
  );

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(
    postSuppressionSettings,
    putSuppressionSettings.setParams({ id: editId }),
    !!editId,
  );

  mapInitialValues((payload) => {
    return {
      name: payload.name,
    };
  });

  onSubmitError((error) => setError(error.message));

  onSubmitSuccess((payload) => {
    handleItemChange && handleItemChange(payload);
    const title = editId ? "List type edited" : "List type added";
    const subtitle = editId
      ? "You have successfully edited list type."
      : "You have successfully added list type.";
    notification.success(title, subtitle);
    closeModal();
  });

  const showToggleBtn = !editId && user?.roles.includes(ADMIN_PERMISSION);

  return (
    <div className={styles.container}>
      <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema}>
        <MessageField message={error} className={styles.errorMsg} />
        <div className={styles.title}>{editId ? "Edit" : "New List Type"}</div>
        <FormField type="text" name="name" label="Name" placeholder="Enter name here..." required />
        {showToggleBtn && <FormField type="switch" name="isGlobal" label="Make this list Global" />}
        <div className={cn(styles.buttons, styles.buttonsWrapper)}>
          <Button appearance="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
};

export { SuppressionSettingsFormPage };
