import { FC } from "react";
import { FormField } from "@epcnetwork/core-ui-kit";

import { hashOptions } from "../reverse.constants";

const HashTypes: FC = () => {
  return (
    <>
      <FormField type="radio" name="hashType" options={hashOptions} disableError />
      <br />
    </>
  );
};

export { HashTypes };
