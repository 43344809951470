import { useSelector } from "react-redux";
import { FC } from "react";
import { Menu, MenuButton, Chip, TableCell, TableRow, TextEllipsis } from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { SuppressionModel } from "models";
import { ADMIN_PERMISSION } from "constants/roles.constants";
import {
  getCategories,
  getEsp,
  getStatus,
  getStatusAppearance,
  getListType,
  getSuppressionKind,
} from "../suppression-list.constants";

import styles from "./table-row.module.css";

type RowProps = {
  item: SuppressionModel;
  refresh: VoidFunction;
  handleEditSuppression: (id: string) => void;
  handleDeleteSuppression: (id: string) => void;
  handleSetEmail: (id: string) => void;
};

const Row: FC<RowProps> = ({
  item,
  handleEditSuppression,
  handleDeleteSuppression,
  handleSetEmail,
}) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const isSameGroup = item.listType.some(
    (list) => (list.userGroup as string) === user?.userGroup?.id && !list.isGlobal,
  );

  const isAdmin = user?.roles.includes(ADMIN_PERMISSION);

  const handleItemDelete = () => {
    handleSetEmail(item.value);
    if (!isSameGroup && !isAdmin) {
      handleDeleteSuppression("");
      return;
    }
    handleDeleteSuppression(item.id);
  };

  const handleItemEdit = () => {
    handleEditSuppression(item.id);
  };

  return (
    <TableRow id={item.id}>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" className={styles.value}>
          {item.value}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis
          className={styles.values}
          tooltipTrigger="hover"
          tooltipPosition="bottom-left"
        >
          {getCategories(item.category)}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis
          className={styles.values}
          tooltipTrigger="hover"
          tooltipPosition="bottom-left"
        >
          {getEsp(item.esp)}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis
          className={styles.values}
          tooltipTrigger="hover"
          tooltipPosition="bottom-left"
        >
          {getListType(item.listType)}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis
          className={styles.values}
          tooltipTrigger="hover"
          tooltipPosition="bottom-left"
        >
          {getSuppressionKind(item.listType)}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <Chip
          className={styles.chip}
          text={getStatus(item.cleaningServiceStatus)}
          appearance={getStatusAppearance(item.cleaningServiceStatus)}
          borderStyle="rounded"
        />
      </TableCell>
      <TableCell>
        <Menu>
          <MenuButton onClick={handleItemEdit} icon="edit">
            Edit
          </MenuButton>
          <MenuButton appearance="delete" icon="delete" onClick={handleItemDelete}>
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export { Row };
