import { FC } from "react";
import { Filters, Select, useAutocomplete } from "@epcnetwork/core-ui-kit";

import { suppressionKindOptions } from "pages/suppression/list/suppression-list.constants";
import { getUsers, getGroups } from "api";
import { FiltersType } from "../suppression-settings.types";
import { nonClearableKeys } from "../suppression-settings.constants";

const initialFilters: FiltersType = {
  userGroup: "",
  user: "",
  isGlobal: "",
};

const ListFilters: FC = () => {
  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");
  const usersAutocomplete = useAutocomplete(getUsers, "userId", "email");

  return (
    <Filters
      initialFilters={initialFilters}
      searchKey="value"
      nonClearableKeys={nonClearableKeys as Array<keyof FiltersType>}
    >
      {({ setSelect, getSelect }) => {
        return (
          <div>
            <Filters.ContentItem>
              <Select
                label="Group"
                onChange={setSelect("userGroup", true)}
                selectedOptionsKeys={getSelect("userGroup", true)}
                asyncOptions={groupsAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Creator"
                onChange={setSelect("user", true)}
                selectedOptionsKeys={getSelect("user", true)}
                asyncOptions={usersAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Kind"
                onChange={setSelect("isGlobal")}
                options={suppressionKindOptions}
                disableError
              />
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};

export { ListFilters };
