import { boolean, object, SchemaOf } from "yup";

import { emailColumnValidation, headersValidation, sanitizeFileName } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat, xlsxFormat } from "constants/file.constants";
import { DiffCommonFileData, Values } from "./diff-common.types";

export const supportedFormats = [csvFormat, xlsxFormat, txtFormat].flat();
export const maxFiles = 2;

export const getInitialData = (item: File): DiffCommonFileData => ({
  fileName: sanitizeFileName(item.name),
  email: { key: "", index: -1 },
  headers: [],
  hasHeaders: false,
});

export const validationSchema: SchemaOf<Values> = object({
  headerValues: object({
    email: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
});
