import { FC, useMemo, useState } from "react";
import { format, differenceInSeconds } from "date-fns";
import cn from "classnames";
import {
  Button,
  notification,
  Menu,
  Chip,
  Tooltip,
  TableCell,
  TableRow,
  useWindowSize,
  useCall,
  MenuButton,
  TextEllipsis,
} from "@epcnetwork/core-ui-kit";

import { getPipelineNameFromResponse } from "utils/pipelines.utils";
import { ExtractComponent } from "types";
import { JobModel } from "models";
import { CircularProgress } from "components";
import { deleteJob, getJobFile } from "api";
import { highlightTime } from "./table-row.constants";

import clockImg from "assets/images/clock.svg";
import errorImg from "assets/images/circle-question.svg";
import downloadImg from "assets/images/circle-download.svg";
import calendarImg from "assets/images/calendar.svg";
import styles from "./table-row.module.css";

type RowProps = {
  item: JobModel;
  refresh: VoidFunction;
};

type AppearanceMap = {
  [key: string]: ExtractComponent<typeof Chip>["appearance"];
};

const chipAppearance: AppearanceMap = {
  done: "success",
  "in-progress": "primary",
  queued: "warning",
  failed: "error",
};

const Row: FC<RowProps> = ({ item, refresh }) => {
  const [isDeleted, setDeleted] = useState(false);
  const { submit, submitting, onCallSuccess, onCallError } = useCall(getJobFile);
  const {
    submit: submitDeleteJob,
    onCallSuccess: onCallSuccessDelete,
    onCallError: onCallErrorDelete,
  } = useCall(deleteJob);

  const createdAtDate = new Date(item.createdAt);
  const finishedAtDate = new Date(item.finishedOn);

  const { width } = useWindowSize();

  const isPaused = item.status === "queued";
  const isDone = item.status === "done";
  const isFailed = item.status === "failed";
  const isProgress = !isDone && !isFailed;
  const isMobile = width < 769;

  const progress = isDone ? 100 : item.progress || 0;
  const isHighlight = differenceInSeconds(new Date(), finishedAtDate) <= highlightTime;

  onCallSuccess((payload) => {
    handleDownloadFile(payload.downloadUrl);
  });

  onCallError((error) => {
    notification.error("Cannot download file", error.message);
  });

  const handleDownloadFile = (downloadUrl: string) => {
    if (downloadUrl) {
      window.open(downloadUrl, "_blank");
    } else {
      notification.error("Downloading error", "Cannot download given file, no download url.");
    }
  };

  const handleGetFile = () => {
    if (item.outputFilename) {
      submit({ data: { fileName: item.outputFilename } });
    } else {
      notification.error("Downloading error", "Cannot download file, try to refresh page");
    }
  };

  const statusAppearance = useMemo(() => {
    return chipAppearance[item.status] || "primary";
  }, [item.status]);

  onCallSuccessDelete(() => {
    setDeleted(true);
    refresh();
    notification.success("Job deleted", "Successfully deleted job");
  });

  onCallErrorDelete((error) => {
    notification.error("Deleting error", error.message);
  });

  const handleDelete = () => {
    notification.confirm("Deleting job?", "Are you sure you want to delete this job?", {
      onOk: () => {
        submitDeleteJob({ params: { jobId: item.id } });
      },
      okText: "Delete",
      icon: "delete",
    });
  };

  const pipeline = getPipelineNameFromResponse(item.pipelineName);

  const status = (
    <div className={cn(styles.actions, { [styles.actionsMobile]: isMobile })}>
      {isProgress && (
        <CircularProgress
          value={progress}
          isPaused={isPaused}
          isFailed={isFailed}
          isDone={isDone}
        />
      )}

      {isDone && (
        <Button
          onClick={handleGetFile}
          appearance="none"
          disabled={submitting}
          className={styles.downloadBtn}
        >
          <img src={downloadImg} width={25} height={25} alt="Download" />
        </Button>
      )}
      {isFailed && (
        <Tooltip
          triggerElement={() => <img src={errorImg} width={25} height={25} alt="Error" />}
          position="bottom"
        >
          <div className={styles.errorTooltip}>
            <div className={styles.errorTitle}>Job failed</div>
            {item.failedReason}
          </div>
        </Tooltip>
      )}
    </div>
  );

  if (isDeleted) {
    return null;
  }

  return (
    <TableRow id={item.jobId} className={cn({ [styles.highlight]: isHighlight })}>
      <TableCell className={styles.id}>#{item.jobId}</TableCell>
      <TableCell className={styles.long}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" className={styles.info}>
          {item.filename}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <div className={styles.cell}>
          <div className={cn({ [styles.mobileWrapper]: isMobile })}>
            <Chip
              text={item.status}
              appearance={statusAppearance}
              className={cn(styles.chip, { [styles.mobileChip]: isMobile })}
            />
          </div>

          {isMobile && status}
        </div>
      </TableCell>
      <TableCell className={styles.long}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" className={styles.info}>
          {item.user}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" className={styles.info}>
          {item.databases?.join(", ")}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.long}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-left" className={styles.info}>
          {pipeline?.title}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.long}>
        <TextEllipsis tooltipTrigger="hover" tooltipPosition="bottom-right" className={styles.info}>
          {item.userGroup?.name || "-"}
        </TextEllipsis>
      </TableCell>
      <TableCell className={styles.date}>
        <img src={calendarImg} alt="" width={11} height={11} />
        {createdAtDate ? format(createdAtDate, "MMM dd, yyyy") : "-"}
        <img src={clockImg} alt="" width={11} height={11} />
        {createdAtDate ? format(createdAtDate, "hh:mm aaa") : "-"}
      </TableCell>
      {!isMobile && <TableCell>{status}</TableCell>}
      {!isMobile && (
        <TableCell>
          <Menu>
            <MenuButton
              appearance="delete"
              onClick={handleDelete}
              icon="delete"
              disabled={!isDone && !isFailed}
            >
              Delete
            </MenuButton>
          </Menu>
        </TableCell>
      )}
    </TableRow>
  );
};

export { Row };
