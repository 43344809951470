import { matchPath } from "react-router-dom";
import { Location } from "history";
import { ArrayElement } from "@epcnetwork/core-ui-kit";

import { DEFAULT_PAGE } from "constants/routes.constants";
import { routes } from "config/routes.config";

export const isActiveRoute = (routePath: string, location: Location, exact = false): boolean => {
  if (routePath === DEFAULT_PAGE.path && location.pathname === "/") {
    return true;
  }

  return Boolean(matchPath(location.pathname, { path: routePath, exact }));
};

export const getActiveRoute = (location: Location): ArrayElement<typeof routes> => {
  const activeRoute = routes.find((route) => isActiveRoute(route.path, location, route.exact));

  if (activeRoute) {
    return activeRoute;
  }
  return routes[routes.length - 1];
};
