import { useHistory } from "react-router-dom";
import { FC, memo, useRef, useState, useCallback } from "react";
import {
  Button,
  Container,
  FileItem,
  FileUpload,
  DropAcceptedFunc,
  useWillUnmount,
  useDidUpdate,
  notification,
  Nullable,
  useFileQueue,
} from "@epcnetwork/core-ui-kit";

import {
  getPipelineRoute,
  getFileUuidName,
  getAcceptedFilesText,
  getSupportedFormats,
  onFileRedirect,
} from "utils";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { postDiffAndCommonFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { DiffCommonFileItem, PipelineDiffAndCommonData } from "./diff-common.types";
import { supportedFormats, maxFiles, getInitialData } from "./diff-common.constants";
import { DiffCommonModalForm } from "./components/diff-common-form-modal";

import exampleImg from "assets/images/diff-and-common-example.svg";
import styles from "./diff-common.module.css";

const DiffCommonPage: FC = memo(() => {
  const componentIsMounted = useRef(true);
  useWillUnmount(() => (componentIsMounted.current = false));
  const history = useHistory();

  const pipeline = getPipelineRoute("Diff and Common");

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitAtOnce,
    hasAllSuccessFiles,
  } = useFileQueue<DiffCommonFileItem, PipelineDiffAndCommonData>(
    pipeline.title,
    postDiffAndCommonFile,
  );

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const [editedFile, setEditedFile] = useState<Nullable<DiffCommonFileItem>>(null);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })),
      );
    },
    [addFiles, createInitialFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleConfigureItem = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: DiffCommonFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleCloseModal = useCallback(() => setEditedFile(null), []);

  const handleSubmit = () => {
    const info: PipelineDiffAndCommonData["filesInfo"] = files.reduce<
      PipelineDiffAndCommonData["filesInfo"]
    >(
      (acc, file) => ({
        ...acc,
        [getFileUuidName(file)]: file.data,
      }),
      {},
    );

    const formData = new FormData();
    formData.append("filesInfo", JSON.stringify(info));
    files.forEach((file) => {
      formData.append("files", file.originalFile, getFileUuidName(file));
    });

    submitAtOnce(
      files.map(({ id }) => id),
      formData,
    );
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, history });
  };

  const handleRedirectToPipelines = () => {
    history.push(PIPELINES_LIST_PAGE.path);
  };

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />

        <div className={styles.form}>
          <div className={styles.example}>
            <img src={exampleImg} alt="" width="444" height="91" />
          </div>
          <FileUpload
            className={styles.dropZone}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            maxFiles={maxFiles}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleConfigureItem}
                onEditValuesClick={handleConfigureItem}
              >
                {data.email.key && (
                  <div className={styles.additionalInfo}>
                    <span>Selected column:</span> {data.email.key}
                  </div>
                )}
              </FileItem>
            ))}
          </div>
          {editedFile && (
            <DiffCommonModalForm
              file={editedFile}
              setClose={handleCloseModal}
              onSubmitClick={handleModalSubmit}
            />
          )}
          <div className={styles.buttons}>
            <Button
              appearance="secondary"
              onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
              disabled={isEntityFinished && !hasAllSuccessFiles}
            >
              Cancel
            </Button>
            {!isEntityFinished && (
              <Button
                onClick={handleSubmit}
                disabled={!hasAllConfigured || files.length < 2}
                loading={!isEntityInConfiguration}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
});

export { DiffCommonPage };
