import { useAbility } from "@casl/react";
import { Ability } from "@casl/ability";

import { AbilityContext } from "components/client/authorization/authorization";

const useAuthorization = (): Ability => {
  return useAbility(AbilityContext);
};

export { useAuthorization };
