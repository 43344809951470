import path from "path";
import * as H from "history";
import { FileQueueItem, notification } from "@epcnetwork/core-ui-kit";

import { FormFileInfo } from "types";
import { FileUploadStateModel } from "models";
import { JOBS_LIST_PAGE, PageType } from "constants/routes.constants";
import {
  error,
  fileErrorMessage,
  fileSuccessMessage,
  success,
} from "constants/notification.constants";

import suppressionImg from "assets/images/status-suppression.svg";
import scheduledImg from "assets/images/status-schedule.svg";
import doneImg from "assets/images/status-done.svg";

//  FILE HELPERS

export const sanitizeFileName = (fileName: string): string => {
  return path.basename(fileName).replace(/\s+/g, "_");
};

export const getFileExtension = (fileName: string): string => {
  return path.extname(fileName);
};

export const getFileUuidName = ({
  id,
  originalFile,
}: Pick<FileQueueItem, "id" | "originalFile"> & Partial<FileQueueItem>): string => {
  return id + getFileExtension(originalFile.name);
};

//  FILE DISPLAYING

type StatusType = {
  name: "In queue" | "Local" | "Global" | "Empty" | "Ready";
  className: string;
};

type StageType = {
  name: "Scheduled" | "Suppression" | "Done";
  img: string;
};

export const stage1: FileUploadStateModel[] = ["scheduled"];
export const stage2: FileUploadStateModel[] = [
  "file-suppression-in-progress",
  "file-suppression-completed",
];
export const stage3: FileUploadStateModel[] = [
  "global-suppression-in-progress",
  "global-suppression-completed",
];
export const stage4: FileUploadStateModel[] = ["file-empty-after-suppression"];

export const getFileStatus = (state: FileUploadStateModel): StatusType => {
  if (stage1.includes(state)) {
    return { name: "In queue", className: "status-grey" };
  }
  if (stage2.includes(state)) {
    return { name: "Local", className: "status-blue" };
  }
  if (stage3.includes(state)) {
    return { name: "Global", className: "status-yellow" };
  }
  if (stage4.includes(state)) {
    return { name: "Empty", className: "status-red" };
  }
  return { name: "Ready", className: "status-green" };
};

export const getFileStage = (state: FileUploadStateModel): StageType => {
  if (stage1.includes(state)) {
    return { name: "Scheduled", img: scheduledImg };
  }
  if (stage2.includes(state)) {
    return { name: "Suppression", img: suppressionImg };
  }
  if (stage3.includes(state)) {
    return { name: "Suppression", img: suppressionImg };
  }
  if (stage4.includes(state)) {
    return { name: "Done", img: doneImg };
  }
  return { name: "Done", img: doneImg };
};

// Files Form Helpers

export const getSelectedColumns = (headers: string[]): FormFileInfo["columns"] => {
  return headers.reduce<FormFileInfo["columns"]>((acc, curr, index) => {
    if (curr) {
      acc.push(String(index));
    }

    return acc;
  }, []);
};

export const getSupportedFormats = (supportedFormats: string[]): string[] => {
  const splitter = ".";
  return supportedFormats
    .filter((format) => format.includes(splitter))
    .map((format) => format.split(splitter)[1]);
};

type FileRedirectType<T extends FileQueueItem> = {
  files: T[];
  clearEntity: () => void;
  page?: PageType;
  history: H.History;
};
export const onFileRedirect = <T extends FileQueueItem>({
  files,
  clearEntity,
  page = JOBS_LIST_PAGE,
  history,
}: FileRedirectType<T>): void => {
  const failedFile = files.find((file) => file.uploadingInfo.uploadingResult === "failed");
  if (!failedFile) {
    clearEntity();
    notification.success(success, fileSuccessMessage);
    history.push(page.path);
    return;
  }
  notification.error(error, failedFile.error || fileErrorMessage);
};
