import { FC, useState } from "react";
import cn from "classnames";
import {
  Button,
  Container,
  List,
  Modal,
  Table,
  useDevice,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { ListTypeModel } from "models";
import { usePayload } from "hooks";
import { searchMinLength } from "constants/form.constants";
import { DeleteErrorModal, Toolbar } from "components";
import { getSuppressionListType } from "api";
import { SuppressionSettingsFormPage } from "../form/suppression-settings-form.page";
import { Row } from "./table-row/table-row";
import { QueryParams } from "./suppression-settings.types";
import {
  initialQueryParams,
  nonClearableKeys,
  tableColumns,
} from "./suppression-settings.constants";
import { ListFilters } from "./list-filters/list-filters";

import addImg from "assets/images/add-with-background.svg";
import styles from "./suppression-settings.module.css";

const SuppressionSettingsListPage: FC = () => {
  const { isMobileDevice } = useDevice();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [value, setIsValue] = useState("");

  const { queryString, searchValue, setSearch } = useFilters<QueryParams>({
    initialState: initialQueryParams,
    searchOptions: { searchKey: "value", searchMinLength },
    nonClearableKeys,
  });

  const dependencies = [queryString];
  const apiResponse = useFetch(getSuppressionListType.setQueryParams(queryString), {
    dependencies,
  });
  const pagination = usePagination({ listPayload: apiResponse.payload });
  const { payload, list, refresh } = usePayload(apiResponse);

  const handleEdit = (id: string) => {
    setEditId(id);
    setModalOpen(true);
  };

  const handleSetValue = (value: string) => {
    setIsValue(value);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setEditId("");
    setModalOpen(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleItemChange = (values: ListTypeModel) => {
    const isExistElement = list.some((el) => el.id === values.id);
    const filteredList = list.filter((listElem) => listElem.id !== values.id);
    const newData: List<ListTypeModel> = {
      ...payload,
      data: [values, ...filteredList],
      total: isExistElement ? Number(payload.total) : Number(payload.total) + 1,
    };
    apiResponse.actions.setData(newData);
    setModalOpen(false);
  };

  return (
    <Container>
      <Toolbar searchValue={searchValue} setSearch={setSearch} filters={<ListFilters />}>
        <Button className={styles.addBtn} onClick={openModal}>
          <img src={addImg} alt="" width={16} height={16} />
          <span className={styles.addBtnText}>New List Type</span>
        </Button>
      </Toolbar>
      <Table
        {...apiResponse}
        error={apiResponse.error?.message}
        list={list}
        columns={tableColumns}
        pagination={{
          ...pagination,
          className: cn({ [styles.pagination]: isMobileDevice }),
        }}
        row={(item) => (
          <Row
            key={item.id}
            item={item}
            refresh={refresh}
            handleEdit={handleEdit}
            handleSetValue={handleSetValue}
            handleOpenDeleteModal={handleOpenDeleteModal}
          />
        )}
      />

      <Modal isOpen={isModalOpen} setClose={closeModal} contentClassName={styles.modal}>
        <SuppressionSettingsFormPage
          closeModal={closeModal}
          handleItemChange={handleItemChange}
          editId={editId}
        />
      </Modal>

      <DeleteErrorModal
        closeModal={handleCloseDeleteModal}
        isOpen={isDeleteModalOpen}
        value={value}
      />
    </Container>
  );
};

export { SuppressionSettingsListPage };
