import { AbilityBuilder, Ability } from "@casl/ability";

import { PermissionsModel, AuthorizationActionType, AuthorizationEntityNameType } from "types";
import { UserModel } from "models";
import {
  PIPELINES_PERMISSIONS,
  PIPELINES_PERMISSION,
  USERS_PERMISSION,
  SUPPRESSION_PERMISSION,
  OPEN_PERMISSION,
  ADMIN_PERMISSION,
  JOBS_PERMISSION,
  SUPPRESSION_LIST_TYPE_PERMISSION,
} from "constants/roles.constants";

export const authorizationSuffixes = {
  create: "_C",
  read: "_R",
  "read-list": "_LIST_R",
  update: "_U",
  delete: "_D",
};

export const createAuthorizationConfig = (user: UserModel | null): Ability => {
  const { can, cannot, rules } = new AbilityBuilder(Ability);

  const canUserWithPermissions = (
    authorizedPermissions: PermissionsModel[],
    action: AuthorizationActionType,
    entityName: AuthorizationEntityNameType,
  ) => {
    const userRoles = user?.roles || [];
    const actionSuffix = authorizationSuffixes[action];
    const contextPermissions = authorizedPermissions.reduce<PermissionsModel[]>(
      (acc, permission) => {
        acc.push(permission);
        acc.push((permission + actionSuffix) as PermissionsModel);
        return acc;
      },
      [],
    );
    const neededPermissions = [ADMIN_PERMISSION, ...contextPermissions];
    const isPermitted = !!neededPermissions.find((permission) => userRoles.includes(permission));
    const isUnlimitedAccess = authorizedPermissions.includes(OPEN_PERMISSION);

    if (isUnlimitedAccess || isPermitted) {
      can(action, entityName);
    } else {
      cannot(action, entityName);
    }
  };

  // Jobs
  canUserWithPermissions([JOBS_PERMISSION.permission], "read", JOBS_PERMISSION.name);
  canUserWithPermissions([JOBS_PERMISSION.permission], "read-list", JOBS_PERMISSION.name);

  // Pipelines
  canUserWithPermissions([PIPELINES_PERMISSION.permission], "read-list", PIPELINES_PERMISSION.name);

  PIPELINES_PERMISSIONS.forEach((pipelinePermission) => {
    canUserWithPermissions(
      [PIPELINES_PERMISSION.permission, pipelinePermission.permission],
      "create",
      pipelinePermission.name,
    );
  });

  // Suppression
  canUserWithPermissions(
    [SUPPRESSION_PERMISSION.permission],
    "create",
    SUPPRESSION_PERMISSION.name,
  );
  canUserWithPermissions([SUPPRESSION_PERMISSION.permission], "read", SUPPRESSION_PERMISSION.name);
  canUserWithPermissions(
    [SUPPRESSION_PERMISSION.permission],
    "delete",
    SUPPRESSION_PERMISSION.name,
  );
  canUserWithPermissions(
    [SUPPRESSION_PERMISSION.permission],
    "read-list",
    SUPPRESSION_PERMISSION.name,
  );

  // Suppression list type
  canUserWithPermissions(
    [SUPPRESSION_LIST_TYPE_PERMISSION.permission],
    "read-list",
    SUPPRESSION_LIST_TYPE_PERMISSION.name,
  );
  canUserWithPermissions(
    [SUPPRESSION_LIST_TYPE_PERMISSION.permission],
    "create",
    SUPPRESSION_LIST_TYPE_PERMISSION.name,
  );
  canUserWithPermissions(
    [SUPPRESSION_LIST_TYPE_PERMISSION.permission],
    "read",
    SUPPRESSION_LIST_TYPE_PERMISSION.name,
  );
  canUserWithPermissions(
    [SUPPRESSION_LIST_TYPE_PERMISSION.permission],
    "delete",
    SUPPRESSION_LIST_TYPE_PERMISSION.name,
  );

  // Users
  canUserWithPermissions([USERS_PERMISSION.permission], "create", USERS_PERMISSION.name);
  canUserWithPermissions([USERS_PERMISSION.permission], "read", USERS_PERMISSION.name);
  canUserWithPermissions([USERS_PERMISSION.permission], "delete", USERS_PERMISSION.name);
  canUserWithPermissions([USERS_PERMISSION.permission], "read-list", USERS_PERMISSION.name);

  return new Ability(rules);
};
