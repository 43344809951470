import { SelectOption } from "@epcnetwork/core-ui-kit";

export const withoutCategoryOption = [
  {
    label: "Without",
    name: "Without",
    id: "without",
    value: "without",
  },
];

export const suppressionKindOptions: SelectOption[] = [
  {
    value: "false",
    label: "Local",
  },
  {
    value: "true",
    label: "Global",
  },
];
