import { useHistory } from "react-router-dom";
import { FC, useCallback, useRef } from "react";
import cn from "classnames";
import {
  Button,
  Container,
  DropAcceptedFunc,
  notification,
  useWillUnmount,
  useDidUpdate,
  useFileQueue,
  FileItem,
  FileUpload,
} from "@epcnetwork/core-ui-kit";

import {
  getAcceptedFilesText,
  getFileUuidName,
  getPipelineRoute,
  getSupportedFormats,
  onFileRedirect,
} from "utils";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { postSumClicksFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { SumClicksFileItem, PipelineSumClicksData } from "./sum-clicks.types";
import { supportedFormats, getInitialData } from "./sum-clicks.constants";

import styles from "./sum-clicks.module.css";

const SuppressPage: FC = () => {
  const componentIsMounted = useRef(true);
  useWillUnmount(() => (componentIsMounted.current = false));
  const history = useHistory();

  const pipeline = getPipelineRoute("Sum Clicks");

  const {
    files,
    createInitialFile,
    addFiles,
    removeFiles,
    clearEntity,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllSuccessFiles,
    submitAtOnce,
  } = useFileQueue<SumClicksFileItem, PipelineSumClicksData>(pipeline.title, postSumClicksFile);

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })),
      );
    },
    [addFiles, createInitialFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleSubmit = () => {
    const info = files.reduce<PipelineSumClicksData["fileInfo"]>(
      (acc, file) => ({ ...acc, [getFileUuidName(file)]: file.data }),
      {},
    );

    const formData = new FormData();
    formData.append("filesInfo", JSON.stringify(info));
    files.forEach((file) => {
      formData.append("files", file.originalFile, getFileUuidName(file));
    });

    submitAtOnce(
      files.map(({ id }) => id),
      formData,
    );
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, history });
  };

  const handleRedirectToPipelines = () => {
    history.push(PIPELINES_LIST_PAGE.path);
  };

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />

        <div className={styles.form}>
          <FileUpload
            className={cn(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                isTuned
              />
            ))}
          </div>
          <div className={styles.buttons}>
            <Button
              appearance="secondary"
              onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
              disabled={isEntityFinished && !hasAllSuccessFiles}
            >
              Cancel
            </Button>
            {!isEntityFinished && (
              <Button
                onClick={handleSubmit}
                disabled={!files.length}
                loading={!isEntityInConfiguration}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export { SuppressPage };
