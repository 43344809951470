import { FC, useState } from "react";
import {
  MessageField,
  NoContent,
  Switch,
  useDidUpdate,
  SelectOption,
} from "@epcnetwork/core-ui-kit";

import { FormFileTableHeaders } from "types";
import { ColumnSelects } from "./components/column-selects";

import globalStyles from "assets/styles/global.module.css";
import styles from "./file-table.module.css";

export type FileTableProps = {
  onChange?: (value: FormFileTableHeaders) => void;
  value?: FormFileTableHeaders | null;
  parsedFile: Array<Array<string>>;
  label?: string;
  error?: string;
  disabled?: boolean;
  loading?: boolean;
  selector?: "select" | "checkbox" | "radio";
  isEmailRequired?: boolean;
  showHeadersSwitch?: boolean;
  autocompleteColumns?: boolean;
  status?: "default" | "error" | "warning" | "success";
};

export const EmailColumn = "Email";

const createInitialArray = (arrayLength: number): SelectOption<string>[] =>
  Array.from({ length: arrayLength }, () => ({ value: "", label: "" }));

const initialValue: FormFileTableHeaders = {
  email: { key: "", index: -1 },
  headers: [],
  hasHeaders: false,
};

const getFirstRow = (parsedFile: string[][]): string[] =>
  parsedFile.find((row) => !!row[0].length) || [];

const FileTable: FC<FileTableProps> = ({
  value,
  parsedFile,
  onChange,
  label = "Set column(s) data type(s)",
  error = "",
  autocompleteColumns = true,
  disabled = false,
  status = "default",
  loading = false,
  showHeadersSwitch = true,
  selector = "select",
}) => {
  const [hasHeaders, setHasHeaders] = useState(Boolean(value?.hasHeaders));
  const [headersData, setHeadersData] = useState<FormFileTableHeaders>(value || initialValue);

  const columnsAmount = parsedFile?.[0]?.length || 0;
  const firstRow = getFirstRow(parsedFile);

  const handleSelectHeaders = (headerValues: SelectOption<string>[], hasHeadersValue: boolean) => {
    const newData: FormFileTableHeaders = {
      email: { key: "", index: -1 },
      headers: headerValues
        .map((header) => (!header ? { value: "", label: "" } : header))
        .map((header) => header.value),
      hasHeaders: hasHeadersValue,
    };

    const emailIndex = headerValues.findIndex((header) => header?.label?.toUpperCase() === "EMAIL");

    if (emailIndex !== -1) {
      newData.email = {
        key: headerValues[emailIndex].value,
        index: emailIndex,
      };
    }

    setHeadersData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  useDidUpdate(() => {
    const { length } = headersData.headers;
    if (!length) {
      handleSelectHeaders(createInitialArray(length), hasHeaders);
    }
  }, [parsedFile]);

  const handleSwitchToggle = (_: unknown, value: boolean) => {
    setHasHeaders(value);
    if (value && autocompleteColumns) {
      let hasEmail = false;
      const newValue = firstRow.map((column) => {
        if ((column || "").toUpperCase() === EmailColumn.toUpperCase() && !hasEmail) {
          hasEmail = true;
          return {
            value: column.toLowerCase(),
            label: EmailColumn,
          };
        }

        return {
          value: "",
          label: "",
        };
      });

      handleSelectHeaders(newValue, value);
    } else if (columnsAmount) {
      handleSelectHeaders(createInitialArray(columnsAmount), value);
    }
  };

  return (
    <div className={styles.itemModal}>
      <div className={styles.itemModalContentWrap}>
        <div className={styles.itemModalTableHeaderWrap}>
          <div className={`${styles.itemModalTableHeaderHint} ${styles.itemModalWrapTitle}`}>
            {label}
          </div>
          {Boolean(parsedFile.length) && showHeadersSwitch && (
            <div className={styles.itemModalTableHasHeaderWrap}>
              Has header
              <Switch
                value="switch"
                checked={hasHeaders}
                onChange={handleSwitchToggle}
                inputSize="small"
                disabled={disabled}
                disableError
              />
              <span>{hasHeaders ? "Yes" : "No"}</span>
            </div>
          )}
        </div>
      </div>
      <div
        className={`${styles.itemModalContentGridContainer} ${styles.hasItems} ${styles[status]}`}
      >
        <div className={`${styles.itemModalContentGrid} ${globalStyles.addScrollStyles}`}>
          <ColumnSelects
            firstRow={firstRow}
            columnsAmount={columnsAmount}
            hasHeaders={hasHeaders}
            headers={headersData.headers}
            setHeaders={handleSelectHeaders}
            disabled={disabled}
            selector={selector}
          />
          {parsedFile.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className={`${styles.itemModalGridRow} ${
                rowIndex === 0 && hasHeaders ? styles.headerRow : ""
              }`}
            >
              {row.map((column, columnIndex) => (
                <div className={styles.itemModalGridCell} key={columnIndex}>
                  <span className={styles.itemModalGridCellText}>{column}</span>
                </div>
              ))}
            </div>
          ))}
          <div className={styles.gridScrollBGRow} />
          {!loading && !parsedFile.length && (
            <div className={styles.noContent}>
              <NoContent
                title="File is empty"
                subtitle="There is no preview after file parsing"
                size="medium"
                showButton={false}
              />
            </div>
          )}
          {loading && <div className={styles.noContent}>Loading file preview...</div>}
        </div>
      </div>
      <MessageField message={error} />
    </div>
  );
};

export { FileTable };
