import { FC } from "react";
import { Filters, Select, DateInput, useAutocomplete, useDevice } from "@epcnetwork/core-ui-kit";

import { useAuthorization } from "hooks";
import { PIPELINES_LIST } from "constants/pipelines.constants";
import { getUsers, getGroups, getDatabasesNames } from "api";
import { getQueryKeys } from "../jobs-list.constants";

import styles from "../jobs-list.module.css";

export type FiltersType = {
  status: string;
  pipelineName: string[];
  databases: string[];
  user: string[];
  userGroup: string[];
  createdAt: string[] | null;
};

const ListFilters: FC = () => {
  const ability = useAuthorization();
  const { isMobileDevice } = useDevice();
  const queryKeys = getQueryKeys(isMobileDevice);
  const nonClearableKeys = ["value", "status"].concat(queryKeys);
  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");
  const usersAutocomplete = useAutocomplete(getUsers, "email", "email");
  const databasesAutocomplete = useAutocomplete(getDatabasesNames, "name", "name");
  const currentDate = new Date();

  const pipelinesOptions = PIPELINES_LIST.filter((pipeline) =>
    ability.can("create", pipeline.authEntity),
  ).map((pipeline) => ({
    value: pipeline.backendName,
    label: pipeline.title,
  }));

  return (
    <Filters searchKey="value" nonClearableKeys={nonClearableKeys}>
      {({ setSelect, getSelect, setDatePickerDate, getDatePickerDate }) => {
        const [startFrom, startTo] = getDatePickerDate("createdAt");
        return (
          <div>
            <Filters.ContentItem>
              <Select
                label="Pipeline"
                onChange={setSelect("pipelineName", true)}
                selectedOptionsKeys={getSelect("pipelineName", true)}
                options={pipelinesOptions}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Database"
                onChange={setSelect("databases", true)}
                selectedOptionsKeys={getSelect("databases", true)}
                asyncOptions={databasesAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="User"
                onChange={setSelect("user", true)}
                selectedOptionsKeys={getSelect("user", true)}
                asyncOptions={usersAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Group"
                onChange={setSelect("userGroup", true)}
                selectedOptionsKeys={getSelect("userGroup", true)}
                asyncOptions={groupsAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <DateInput
                className={styles.inputDate}
                dateFrom={startFrom}
                dateTo={startTo}
                max={currentDate}
                onChange={setDatePickerDate("createdAt", true)}
                isMulti
                disableError
              />
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};

export { ListFilters };
