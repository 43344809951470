import { FC, useState } from "react";
import cn from "classnames";
import {
  Button,
  Form,
  FormField,
  MessageField,
  notification,
  Nullable,
  useAutocomplete,
  useFormValues,
  useSubmit,
  SelectOption,
} from "@epcnetwork/core-ui-kit";

import { ListTypeModel } from "models";
import {
  getESPs,
  getSuppression,
  getSuppressionCategories,
  getSuppressionCleaningServicesStatuses,
  getSuppressionListTypeSimple,
  postSuppression,
  putSuppression,
} from "api";
import { SuppressionFormProps } from "./suppression-form.types";
import {
  initialValues,
  suppressionTypes,
  suppressionKindOptions,
  validationSchema,
} from "./suppression-form.constants";
import { Popup } from "./components/popup/popup";

import styles from "./suppression-form.module.css";

const EmailsSuppressionFormPage: FC<SuppressionFormProps> = ({
  closeModal,
  handleItemChange,
  editId,
}) => {
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [type, setType] = useState("");
  const [selectedListType, setSelectedListType] = useState<Nullable<ListTypeModel[]>>(null);
  const [selectedKind, setSelectedKind] = useState("");

  const handleClosePopup = () => {
    setError("");
    setIsOpen(false);
  };

  const { formProps, mapInitialValues } = useFormValues(
    initialValues,
    getSuppression.setParams({ suppressionId: editId }),
    !!editId,
  );

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(
    postSuppression,
    putSuppression.setParams({ id: editId }),
    !!editId,
  );

  mapInitialValues((payload) => {
    setValue(payload.value);
    setType(payload.type);
    setSelectedListType(payload.listType);

    return {
      value: payload.value,
      esp: payload.esp.map(({ id }) => id),
      category: payload.category.map(({ id }) => id),
      cleaningServiceStatus: payload.cleaningServiceStatus?.id,
      tags: payload.tags.map(({ id }) => id),
      type: payload.type,
      listType: payload.listType.map(({ id }) => id),
    };
  });

  onSubmitError((error) => setError(error.message));

  onSubmitSuccess((payload) => {
    handleItemChange(payload);
    const title = editId ? "Suppression edited" : "Suppression added";
    const subtitle = editId
      ? "You have successfully edited suppression."
      : "You have successfully added suppression.";
    notification.success(title, subtitle);
  });

  const isDisabled = !value || !type || !value.trim() || !selectedListType;
  const buttonType = editId ? "submit" : "button";
  const espAutocomplete = useAutocomplete(getESPs, "id", "name");
  const categoriesAutocomplete = useAutocomplete(getSuppressionCategories, "id", "name");
  const cleaningServicesStatusesAutocomplete = useAutocomplete(
    getSuppressionCleaningServicesStatuses,
    "id",
    "name",
  );
  const listTypeAutocomplete = useAutocomplete(getSuppressionListTypeSimple, "id", "name");
  const mapOptions = (
    options: (typeof listTypeAutocomplete)["fetchOptions"],
  ): (typeof listTypeAutocomplete)["fetchOptions"] => {
    const kind = selectedKind ? selectedKind === "true" : "";

    return options.filter((opt) => (selectedKind ? Boolean(opt.isGlobal) === kind : options));
  };

  const handleChangeListType = (listType: ListTypeModel[]) => {
    setSelectedListType(listType);
  };

  const handleChangeListKind = (option: SelectOption) => {
    if (option) return setSelectedKind(option.value as string);
    setSelectedKind("");
  };

  const handleOpenPopup = () => {
    if (editId) return;

    setIsOpen(true);
  };

  const disableClearing = selectedListType?.filter((list) => list.locked);

  return (
    <div className={styles.container}>
      <Form
        {...formProps}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {editId && <MessageField message={error} className={styles.errorMsg} />}
        <div className={styles.title}>{editId ? "Edit" : "Suppression Form"}</div>
        <div className={styles.row}>
          <FormField
            type="select"
            name="type"
            label="Type"
            placeholder="Choose type from the list"
            options={suppressionTypes}
            disabled={!!editId}
            disableClearing={!!editId}
            disableArrow={!!editId}
            onChange={(type) => setType(type)}
            required
          />
          <FormField
            type="select"
            name="category"
            label="Category"
            placeholder="Choose Category(ies) from the list"
            asyncOptions={categoriesAutocomplete}
            isMulti
          />
          <FormField
            type="text"
            name="value"
            label="Value"
            placeholder="Enter Email"
            disabled={!!editId}
            onChange={(value) => setValue(value)}
            required
          />
          <FormField
            type="select"
            name="esp"
            label="Esp"
            placeholder="Choose ESP(s) from the list"
            asyncOptions={espAutocomplete}
            isMulti
          />
          <FormField
            type="select"
            name="suppressionKind"
            label="Suppression Kind"
            placeholder="Choose Suppression Kind"
            options={suppressionKindOptions}
            onChange={handleChangeListKind}
          />
          <FormField
            type="select"
            name="cleaningServiceStatus"
            label="Email list verification service status"
            placeholder="Choose ELVSS from the list"
            asyncOptions={cleaningServicesStatusesAutocomplete}
          />
          <FormField
            type="select"
            name="listType"
            label="List type"
            placeholder="Select from the list"
            asyncOptions={{
              ...listTypeAutocomplete,
              fetchOptions: mapOptions(listTypeAutocomplete.fetchOptions),
            }}
            disableClearing={!!disableClearing?.length}
            onChange={handleChangeListType}
            isMulti
            isSearchable
            required
          />
        </div>

        {isOpen && (
          <Popup isOpen={isOpen} setClose={handleClosePopup} value={value} error={error} />
        )}
        <div className={cn(styles.buttons, styles.buttonsWrapper)}>
          <Button appearance="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type={buttonType} onClick={handleOpenPopup} disabled={isDisabled}>
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export { EmailsSuppressionFormPage };
