import { PipelineWarmUpData } from "pages/pipelines/forms/warm-up/warm-up.types";
import { PipelineSuppressData } from "pages/pipelines/forms/suppress/suppress.types";
import { PipelineSumClicksData } from "pages/pipelines/forms/sum-clicks/sum-clicks.types";
import { PipelineSplitData } from "pages/pipelines/forms/split/split.types";
import { PipelineSHA256Data } from "pages/pipelines/forms/sha256/sha256.types";
import { PipelineReverseData } from "pages/pipelines/forms/reverse/reverse.types";
import { PipelineMergeData } from "pages/pipelines/forms/merge/merge.types";
import { PipelineMD5Data } from "pages/pipelines/forms/md5/md5.types";
import { PipelineMatchData } from "pages/pipelines/forms/match/match.types";
import { PipelineISPDomainSplitterData } from "pages/pipelines/forms/isp-splitter/isp-splitter.types";
import { PipelineGavinData } from "pages/pipelines/forms/gavin/gavin.types";
import { PipelineExtractData } from "pages/pipelines/forms/extract/extract.types";
import { PipelineDomainPurifyData } from "pages/pipelines/forms/domain-purify/domain-purify.types";
import { PipelineDiffAndCommonData } from "pages/pipelines/forms/diff-common/diff-common.types";
import { PipelineDeduplicateData } from "pages/pipelines/forms/deduplicate/deduplicate.types";
import { PipelineCutData } from "pages/pipelines/forms/cut/cut.types";
import { PipelineConvertData } from "pages/pipelines/forms/convert/convert.types";
import { apiMiddleware } from "api";
import { PipelinesDomain, PipelineFileEmailsDomainsGetData } from "./pipelines.interface";

export const getFileEmailsDomains = apiMiddleware<
  { domains: PipelinesDomain[]; total: number },
  PipelineFileEmailsDomainsGetData | FormData
>()({
  method: "post",
  endpoint: "/pipeline/count-isps",
  isFormData: true,
});

export const postDeduplicateFile = apiMiddleware<undefined, PipelineDeduplicateData>()({
  method: "post",
  endpoint: "/pipeline/deduplicate",
  isFormData: true,
});

export const postDiffAndCommonFile = apiMiddleware<undefined, PipelineDiffAndCommonData>()({
  method: "post",
  endpoint: "/pipeline/subtract",
  isFormData: true,
});

export const postExtractFile = apiMiddleware<undefined, PipelineExtractData>()({
  method: "post",
  endpoint: "/pipeline/extractemail",
  isFormData: true,
});

export const postGavinFile = apiMiddleware<undefined, PipelineGavinData>()({
  method: "post",
  endpoint: "/pipeline/gavin",
  isFormData: true,
});

export const postISPDomainSplitterFile = apiMiddleware<undefined, PipelineISPDomainSplitterData>()({
  method: "post",
  endpoint: "/pipeline/ispSplitter",
  isFormData: true,
});

export const postMatchFile = apiMiddleware<undefined, PipelineMatchData>()({
  method: "post",
  endpoint: "/pipeline/matchEmails",
  isFormData: true,
});

export const postMD5File = apiMiddleware<undefined, PipelineMD5Data>()({
  method: "post",
  endpoint: "/pipeline/md5",
  isFormData: true,
});

export const postSHA256File = apiMiddleware<undefined, PipelineSHA256Data>()({
  method: "post",
  endpoint: "/pipeline/sha256",
  isFormData: true,
});

export const postMergeFile = apiMiddleware<undefined, PipelineMergeData>()({
  method: "post",
  endpoint: "/pipeline/merge",
  isFormData: true,
});

export const postSumClicksFile = apiMiddleware<undefined, PipelineSumClicksData>()({
  method: "post",
  endpoint: "/pipeline/sumClicks",
  isFormData: true,
});

export const postSuppressFile = apiMiddleware<undefined, PipelineSuppressData>()({
  method: "post",
  endpoint: "/pipeline/suppress",
  isFormData: true,
});

export const postReverseFile = apiMiddleware<undefined, PipelineReverseData>()({
  method: "post",
  endpoint: "/pipeline/reverse",
  isFormData: true,
});

export const postSplitFile = apiMiddleware<undefined, PipelineSplitData>()({
  method: "post",
  endpoint: "/pipeline/splitFile",
  isFormData: true,
});

export const postWarmUpSplitterFile = apiMiddleware<undefined, PipelineWarmUpData>()({
  method: "post",
  endpoint: "/pipeline/warmUpSplitter",
  isFormData: true,
});

export const postDomainPurifyFile = apiMiddleware<undefined, PipelineDomainPurifyData>()({
  method: "post",
  endpoint: "/pipeline/domainPurifier",
  isFormData: true,
});

export const postConvertFile = apiMiddleware<undefined, PipelineConvertData>()({
  method: "post",
  endpoint: "/pipeline/convert",
  isFormData: true,
});

export const postCutFile = apiMiddleware<undefined, PipelineCutData>()({
  method: "post",
  endpoint: "/pipeline/csvcut",
  isFormData: true,
});
