import { useHistory } from "react-router-dom";
import { FC } from "react";
import cn from "classnames";
import { Button } from "@epcnetwork/core-ui-kit";

import { JOBS_LIST_PAGE } from "constants/routes.constants";

import styles from "../export-modal.module.css";

type ConfirmMessageProps = {
  closeModal: VoidFunction;
  fileName: string;
};

const ConfirmMessage: FC<ConfirmMessageProps> = ({ closeModal, fileName }) => {
  const history = useHistory();
  const handleMoveToJobs = () => history.push(`${JOBS_LIST_PAGE.path}?offset=0&value=${fileName}`);

  return (
    <>
      <div className={styles.title}>Next Step</div>
      <div className={styles.subtitle}>
        The file will be displayed on the Job page when it's ready.
      </div>
      <div className={cn(styles.buttons, styles.buttonsWrapper)}>
        <Button type="button" appearance="text" onClick={closeModal}>
          Back to Suppression List
        </Button>
        <Button type="button" onClick={handleMoveToJobs}>
          Go to Jobs Page
        </Button>
      </div>
    </>
  );
};

export { ConfirmMessage };
