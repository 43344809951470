import { useSelector } from "react-redux";
import { createContext, useMemo, FC } from "react";

import { RootState } from "store";
import { createAuthorizationConfig } from "config/authorization.config";

const initialAbility = createAuthorizationConfig(null);

export const AbilityContext = createContext(initialAbility);

const AuthorizationGuard: FC = ({ children }) => {
  const { user } = useSelector((state: RootState) => state.auth);

  const ability = useMemo(() => {
    return createAuthorizationConfig(user);
  }, [user]);

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export { AuthorizationGuard };
