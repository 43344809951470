import { array, boolean, number, object, SchemaOf, string } from "yup";

import {
  emailColumnValidation,
  headersValidation,
  sanitizeFileName,
  selectNumberValidation,
} from "utils";
import { minOneFieldText, minZeroFieldText, requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat, xlsxFormat } from "constants/file.constants";
import { Values, WarmUpFileData } from "./warm-up.types";

export const supportedFormats = [csvFormat, xlsxFormat, txtFormat].flat();
export const typeOptions = [
  {
    label: "Email amount",
    value: 1,
  },
  {
    label: "With %",
    value: 2,
  },
];

export const getInitialData = (item: File): WarmUpFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  email: { key: "", index: -1 },
  type: typeOptions[0].value,
  days: [{ data: [{ numberOfEmails: 100, domain: "1" }] }],
});

export const validationSchema: SchemaOf<Values> = object({
  headerValues: object({
    email: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
  daysNumber: number().min(1, minOneFieldText).required(requiredFieldText),
  type: selectNumberValidation(),
  domainNumber: number().min(1, minOneFieldText).required(requiredFieldText),
  initialEmailsNumber: number().min(1, minOneFieldText).required(requiredFieldText),
  domainsEmailsSpread: array()
    .of(
      object()
        .shape({
          domain: string().min(1, minOneFieldText).required(requiredFieldText),
          numberOfEmails: number().min(0, minZeroFieldText).required(requiredFieldText),
        })
        .required(requiredFieldText),
    )
    .test("emailsCount", "You must use all of the emails", (value, context) => {
      const { initialEmailsNumber } = context.parent;
      const usedEmails = value?.reduce((acc, curr) => {
        acc += Number(curr.numberOfEmails);
        return acc;
      }, 0);

      return usedEmails === Number(initialEmailsNumber);
    })
    .test("domainsCount", "You must select all domains inputs", (value, context) => {
      const { domainNumber } = context.parent;

      return Number(domainNumber) === value?.length;
    })
    .min(1, minOneFieldText)
    .required(requiredFieldText),
});
