import { boolean, object, SchemaOf } from "yup";

import { emailColumnValidation, headersValidation, sanitizeFileName } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { Values, MergeFileData } from "./merge.types";

export const supportedFormats = [csvFormat, txtFormat].flat();
export const maxFiles = 15;

export const getInitialData = (item: File): MergeFileData => ({
  fileName: sanitizeFileName(item.name),
  email: { key: "", index: -1 },
  headers: [],
  hasHeaders: false,
});

export const validationSchema: SchemaOf<Values> = object({
  headerValues: object({
    email: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
});
