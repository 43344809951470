import { array, boolean, mixed, object, SchemaOf, string } from "yup";

import {
  headersValidation,
  sanitizeFileName,
  selectMultipleValidation,
  suppressionDatabaseValidation,
} from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { HashTypesEnum, Values, ReverseFileData } from "./reverse.types";

export const supportedFormats = [csvFormat, txtFormat].flat();
export const suppressionDbName = "suppression";

export const hashOptions = [
  { value: HashTypesEnum.md5, label: "MD5" },
  { value: HashTypesEnum.sha256, label: "SHA256" },
];

export const validationSchema: SchemaOf<Values> = object({
  headerValues: object({
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
  databases: selectMultipleValidation(),
  hashType: mixed<HashTypesEnum>().oneOf(Object.values(HashTypesEnum)).required(requiredFieldText),
  listTypes: suppressionDatabaseValidation(),
  isGlobal: boolean(),
  categories: array().of(string()).notRequired(),
});

export const getInitialData = (item: File): ReverseFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  email: { key: "", index: -1 },
  databases: [],
  hashType: hashOptions[0].value,
  md5: { key: "", index: -1 },
});
