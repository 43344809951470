import { FC } from "react";
import { Filters, Select, DateInput, useAutocomplete } from "@epcnetwork/core-ui-kit";

import {
  getESPs,
  getUsers,
  getGroups,
  getSuppressionCategories,
  getSuppressionCleaningServicesStatuses,
  getSuppressionListTypeSimple,
} from "api";
import { FiltersType } from "../suppression-list.types";
import { nonClearableKeys, suppressionKindOptions } from "../suppression-list.constants";
import { suppressionFilterTypes } from "../../form/suppression-form.constants";

import styles from "../suppression-list.module.css";

const initialFilters: FiltersType = {
  user: "",
  type: "",
  userGroup: "",
  listType: "",
  isGlobal: "",
  category: "",
  esp: "",
  cleaningServiceStatus: "",
  createdAt: [""],
};

const ListFilters: FC = () => {
  const espAutocomplete = useAutocomplete(getESPs, "id", "name");
  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");
  const usersAutocomplete = useAutocomplete(getUsers, "userId", "email");
  const listAutocomplete = useAutocomplete(getSuppressionListTypeSimple, "id", "name");
  const categoryAutocomplete = useAutocomplete(getSuppressionCategories, "id", "name");
  const statusAutocomplete = useAutocomplete(getSuppressionCleaningServicesStatuses, "id", "name");
  const currentDate = new Date();

  const getListTypeOptions = (isGlobal: string) => {
    const kind = isGlobal && isGlobal === "true";
    const options = isGlobal
      ? listAutocomplete.fetchOptions.filter((el) => Boolean(el.isGlobal) === kind)
      : listAutocomplete.fetchOptions;

    return options.map((list) => {
      return {
        ...list,
        locked: list.locked && false,
      };
    });
  };

  return (
    <Filters
      initialFilters={initialFilters}
      searchKey="value"
      nonClearableKeys={nonClearableKeys as Array<keyof FiltersType>}
    >
      {({ setSelect, getSelect, getDatePickerDate, setDatePickerDate, state }) => {
        const [startFrom, startTo] = getDatePickerDate("createdAt");

        return (
          <div>
            <Filters.ContentItem>
              <Select
                label="User"
                onChange={setSelect("user", true)}
                selectedOptionsKeys={getSelect("user", true)}
                asyncOptions={usersAutocomplete}
                isSearchable
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Type"
                onChange={setSelect("type", true)}
                selectedOptionsKeys={getSelect("type", true)}
                options={suppressionFilterTypes}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Kind of list type"
                selectedOptionsKeys={getSelect("isGlobal")}
                onChange={setSelect("isGlobal")}
                options={suppressionKindOptions}
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="List type"
                onChange={setSelect("listType", true)}
                selectedOptionsKeys={getSelect("listType", true)}
                options={getListTypeOptions(state.isGlobal)}
                isSearchable
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Category"
                onChange={setSelect("category", true)}
                selectedOptionsKeys={getSelect("category", true)}
                asyncOptions={categoryAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Group"
                onChange={setSelect("userGroup", true)}
                selectedOptionsKeys={getSelect("userGroup", true)}
                asyncOptions={groupsAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="ESP"
                onChange={setSelect("esp", true)}
                selectedOptionsKeys={getSelect("esp", true)}
                asyncOptions={espAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <Select
                label="Status"
                onChange={setSelect("cleaningServiceStatus", true)}
                selectedOptionsKeys={getSelect("cleaningServiceStatus", true)}
                asyncOptions={statusAutocomplete}
                isMulti
                disableError
              />
            </Filters.ContentItem>
            <Filters.ContentItem>
              <DateInput
                className={styles.inputDate}
                dateFrom={startFrom}
                dateTo={startTo}
                max={currentDate}
                onChange={setDatePickerDate("createdAt", true)}
                isMulti
                disableError
              />
            </Filters.ContentItem>
          </div>
        );
      }}
    </Filters>
  );
};

export { ListFilters };
