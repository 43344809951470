import { useHistory } from "react-router-dom";
import { FC } from "react";
import { Button } from "@epcnetwork/core-ui-kit";

import { LOGIN_PAGE } from "constants/routes.constants";
import { LoginSlider } from "../../login/login-slider/login-slider";

import logo from "assets/images/logo.svg";
import styles from "../send-email/send-email.module.css";

const ForgotPasswordHeader: FC = () => {
  const history = useHistory();
  const moveToLogin = () => history.push(LOGIN_PAGE.path);

  return (
    <>
      <div className={styles.logo}>
        <img className={styles.icon} src={logo} width="36" height="36" alt="Company logo" />
        <div className={styles.logoText}>Mergex</div>
        <div className={styles.topBar}>
          <span className={styles.topBarText}>Remembered your credentials?</span>
          <Button appearance="text" className={styles.link} onClick={moveToLogin}>
            Log In
          </Button>
        </div>
      </div>
      <div className={styles.sidebar}>
        <div className={styles.content}>
          <LoginSlider />
          <div className={styles.info}>
            <div className={styles.option}>&#169;2021 Mergex</div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ForgotPasswordHeader };
