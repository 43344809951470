import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import { Form, FormButtons, Button, Modal, FormField, Label, Tabs } from "@epcnetwork/core-ui-kit";

import { getAcceptedFilesText, getSupportedFormats } from "utils";
import { DomainPurifyFileItem, Values } from "../domain-purify.types";
import { matchOptions, supportedFormats, validationSchema } from "../domain-purify.constants";

import styles from "../domain-purify.module.css";

type FormModalProps = {
  file: DomainPurifyFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: DomainPurifyFileItem) => void;
};

const FormModal: FC<FormModalProps> = ({ file, setClose, onSubmitClick }) => {
  const [activeTab, setActiveTab] = useState(file.data.useFile ? 1 : 0);

  const [initialValues] = useState<Values>({
    useFile: file.data.useFile,
    match: !file.data.match ? "remove" : "get",
    domains: file.data.domains?.[0],
    domainFormFile: file.data.domainFormFile,
  });

  const onSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file,
        domainFormFile: values.domainFormFile,
        match: values.match !== "remove",
        domains: values.domains?.split("\n"),
        useFile: values.useFile,
        domainFile: false,
        fileName: file.originalFile.name,
      },
    });
    setSubmitting(false);
  };

  const tabsComponent = [
    {
      tab: { name: "Enter text" },
      tabComponent: (
        <div className={styles.textareaWrapper}>
          <FormField
            type="textarea"
            name="domains"
            placeholder="Type Domains, separate them with a new line"
            rows={9}
          />
        </div>
      ),
    },
    {
      tab: { name: "Upload File" },
      tabComponent: (
        <div className={styles.fileWrapper}>
          <FormField
            type="file"
            name="domainFormFile"
            maxFiles={1}
            accept={supportedFormats}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
          />
        </div>
      ),
    },
  ];

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose}>
      <h2>Set values {file.originalFile.name}</h2>
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => (
          <>
            <Label text="Choose an action" className={styles.label} />
            <FormField
              type="radio"
              options={matchOptions}
              name="match"
              disableError
              className={styles.radio}
            />

            <Label text="Select method" className={styles.label} />
            <div className={styles.tabWrapper}>
              <Tabs
                maxDesktopWidth={600}
                tabs={tabsComponent}
                activeTab={activeTab}
                onTabClick={(val) => {
                  setFieldValue("useFile", val !== 0);
                  setActiveTab(val);
                }}
              />
            </div>

            <FormButtons>
              <Button appearance="secondary" onClick={setClose}>
                Cancel
              </Button>
              <Button type="submit">Set values</Button>
            </FormButtons>
          </>
        )}
      </Form>
    </Modal>
  );
};

export { FormModal };
