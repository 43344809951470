import { ArrayElement } from "@epcnetwork/core-ui-kit";

import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { PIPELINES_LIST } from "constants/pipelines.constants";
import { DatabaseModel } from "api/databases/databases.interface";

export const getPipelineRoute = (
  name: ArrayElement<typeof PIPELINES_LIST>["title"],
): ArrayElement<typeof PIPELINES_LIST> => {
  return PIPELINES_LIST.find((pipeline) => pipeline.title === name) as ArrayElement<
    typeof PIPELINES_LIST
  >;
};

export const getPipelineNameFromResponse = (
  backendName: string,
): ArrayElement<typeof PIPELINES_LIST> | undefined => {
  return PIPELINES_LIST.find((pipeline) => pipeline.backendName === backendName);
};

export const isPipelineListRoute = (pathname: string): boolean => {
  return pathname.includes(PIPELINES_LIST_PAGE.name.toLocaleLowerCase());
};

// Note: if you'll need to have new custom filters for databases,
// discuss this logic with PM to modify backend part
export const filterDatabasesPipelines = <T extends DatabaseModel>(databases?: T[]): T[] => {
  if (databases?.length) {
    return databases.filter((database) => database.name !== "suppression");
  }
  return [];
};
