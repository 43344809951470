import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { Statuses, FiltersQueryType, TableColumnsType } from "./jobs-list.types";

export const tableColumns = (isMobile: boolean): TableColumnsType[] => [
  { label: "ID", queryKey: "jobId" },
  { label: "File Name", queryKey: "filename" },
  { label: "Status", queryKey: "status" },
  { label: "User", queryKey: "user" },
  { label: "Databases", queryKey: "databases" },
  { label: "Pipeline", queryKey: "pipelineName" },
  { label: "Group", queryKey: "userGroup" },
  { label: "Created at", queryKey: "createdAt" },
  { label: "", selected: !isMobile },
  { label: "", selected: !isMobile },
];

export const tabStatesMap: Record<number, Statuses> = {
  0: "",
  1: "done",
  2: "in progress",
  3: "queued",
  4: "failed",
};

export const getQueryKeys = (isMobile: boolean): string[] =>
  tableColumns(isMobile).map(({ queryKey }) => `order[${queryKey}]`);

export const initialFilters: UseFiltersProps<FiltersQueryType> = {
  initialState: {
    search: "",
    status: "",
    offset: 0,
  },
};
