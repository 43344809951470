import { FC, useRef, useState } from "react";
import { FormikHelpers, FormikProps } from "formik";
import {
  Button,
  Form,
  FormButtons,
  FormField,
  MessageField,
  Modal,
  useAutocomplete,
  useFileParser,
} from "@epcnetwork/core-ui-kit";

import { filterDatabasesPipelines } from "utils";
import { FormField as LocalFormField } from "components";
import { getDatabasesNames } from "api";
import { ReverseFileItem, Values } from "../reverse.types";
import { validationSchema } from "../reverse.constants";
import { HashTypes } from "./hash-types";

import styles from "../reverse.module.css";

type ReverseModalFormProps = {
  file: ReverseFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: ReverseFileItem) => void;
};

const ReverseModalForm: FC<ReverseModalFormProps> = ({ file, setClose, onSubmitClick }) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<Values>({
    headerValues: {
      hasHeaders: file.data.hasHeaders,
      headers: file.data.headers,
    },
    databases: file.data.databases,
    hashType: file.data.hashType,
    categories: file.data.categories,
    listTypes: file.data.listTypes,
    isGlobal: file.data.isGlobal,
  });

  const formRef = useRef<FormikProps<Values> | null>(null);

  const databasesAutocomplete = useAutocomplete(getDatabasesNames, "name", "name");
  databasesAutocomplete.fetchOptions = filterDatabasesPipelines(databasesAutocomplete.fetchOptions);

  const onSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    const md5 = values.headerValues.headers.reduce(
      (acc, curr, index) => {
        if (curr) {
          acc.index = index;
          acc.key = curr;
        }
        return acc;
      },
      { key: "", index: -1 },
    );

    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.headerValues,
        databases: values.databases,
        md5,
        hashType: values.hashType,
        listTypes: values.listTypes,
        categories: values.categories,
        isGlobal: values.isGlobal,
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose} contentClassName={styles.modal}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
        innerRef={formRef}
      >
        <FormField
          type="select"
          name="databases"
          label="Databases"
          placeholder="Databases"
          asyncOptions={databasesAutocomplete}
          isMulti
          canSelectAll
        />
        <HashTypes />
        <LocalFormField
          type="file-table"
          name="headerValues"
          label="Select Email column"
          parsedFile={parsedFile}
          loading={loading}
          selector="radio"
          autocompleteColumns={false}
        />
        <FormButtons>
          <Button appearance="secondary" onClick={setClose}>
            Cancel
          </Button>
          <Button type="submit">Set values</Button>
        </FormButtons>
      </Form>
    </Modal>
  );
};

export { ReverseModalForm };
