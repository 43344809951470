import { boolean, object, SchemaOf, string } from "yup";

import { requiredFieldText, searchMinLengthFieldText } from "constants/form.constants";
import {
  SuppressionSettingsRequiredValues,
  SuppressionSettingsValues,
} from "./suppression-settings-form.types";

export const initialValues: SuppressionSettingsValues = {
  name: "",
  isGlobal: undefined,
};

export const validationSchema: SchemaOf<SuppressionSettingsRequiredValues> = object().shape({
  name: string().min(3, searchMinLengthFieldText).required(requiredFieldText),
  isGlobal: boolean(),
});
