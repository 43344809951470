import { FC } from "react";
import { Button, MessageField, Form, FormButtons, FormField } from "@epcnetwork/core-ui-kit";

import { Hint } from "components";
import { ForgotPasswordHeader } from "../ forgot-password-header/forgot-password-header";
import { useSendEmailHook } from "./send-email.hook";
import { initialValues, validationSchema } from "./send-email.constants";
import { ConfirmMessage } from "./confirm-message/confirm-message";

import styles from "./send-email.module.css";

const SendEmail: FC = () => {
  const { onSubmit, errorMessage, isLinkExpired, isEmailSent, handleSent } = useSendEmailHook();

  return (
    <div className={styles.container}>
      <ForgotPasswordHeader />
      <div className={styles.loginForm}>
        {isEmailSent ? (
          <ConfirmMessage handleSent={handleSent} />
        ) : (
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            <div className={styles.title}>Forgot password ?</div>
            <div className={styles.subtitle}>
              Enter the email address you used when you joined and we’ll send you instructions to
              reset password.
            </div>
            <MessageField message={errorMessage} align="left" />
            <FormField type="text" name="email" label="Email" placeholder="Fill your email" />
            {isLinkExpired && (
              <Hint type="error">
                The reset password request has expired. <br />
                Please use the form below to send a new password reset email
              </Hint>
            )}
            <FormButtons align="right">
              <Button className={styles.btn} type="submit">
                Send
              </Button>
            </FormButtons>
          </Form>
        )}
      </div>
    </div>
  );
};

export { SendEmail };
