import { Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FC, useState } from "react";
import { useCall, useDidUpdate, Loader } from "@epcnetwork/core-ui-kit";

import { RootState, setUser } from "store";
import { routes, Route } from "config/routes.config";
import { getMyProfile } from "api";

const App: FC = () => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state: RootState) => state.auth);
  const [isLoading, setIsLoading] = useState(true);

  const { submit, onCallSuccess, onCallError } = useCall(getMyProfile);

  useDidUpdate(
    () => {
      if (token && !user) {
        setIsLoading(true);
        submit();
      } else {
        setIsLoading(false);
      }
    },
    [token, user],
    true,
  );

  onCallSuccess(async (payload) => {
    dispatch(setUser(payload));
    setIsLoading(false);
  });

  onCallError(() => {
    setIsLoading(false);
  });

  if (isLoading) {
    return <Loader type="puff-loader" position="absolute" />;
  }

  return (
    <Switch>
      {routes.map((route) => (
        <Route key={route.path} {...route} />
      ))}
    </Switch>
  );
};

export { App };
