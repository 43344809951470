import { sanitizeFileName } from "utils";
import {
  csvFormat,
  docFormat,
  docxFormat,
  gzFormat,
  msgFormat,
  pdfFormat,
  rtfFormat,
  tarFormat,
  txtFormat,
  xlsFormat,
  xlsxFormat,
  zipFormat,
} from "constants/file.constants";
import { ExtractFileData } from "./extract.types";

export const supportedFormats = [
  csvFormat,
  xlsxFormat,
  xlsFormat,
  txtFormat,
  pdfFormat,
  docFormat,
  docxFormat,
  rtfFormat,
  msgFormat,
  tarFormat,
  gzFormat,
  zipFormat,
].flat();

export const getInitialData = (item: File): ExtractFileData => ({
  fileName: sanitizeFileName(item.name),
});
