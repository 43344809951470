import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  Button,
  Modal,
  MessageField,
  FormButtons,
  useAutocomplete,
  FormField,
  useFileParser,
} from "@epcnetwork/core-ui-kit";

import { ListTypeSection } from "pages/pipelines/forms/components/list-type-section/list-type-section";
import { FormField as LocalFormField } from "components";
import { DatabaseModel } from "api/databases/databases.interface";
import { getDatabasesNames } from "api";
import { SuppressFileItem, Values } from "../suppress.types";
import { validationSchema } from "../suppress.constants";

import styles from "../suppress.module.css";

type SuppressModalFormProps = {
  file: SuppressFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: SuppressFileItem) => void;
};

const SuppressModalForm: FC<SuppressModalFormProps> = ({ file, setClose, onSubmitClick }) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);
  const [selectedDatabases, setSelectedDatabases] = useState<string[]>(file.data.databases);

  const [initialValues] = useState<Values>({
    headerValues: {
      hasHeaders: file.data.hasHeaders,
      email: file.data.email,
      headers: file.data.headers,
    },
    databases: file.data.databases,
    categories: file.data.categories,
    listTypes: file.data.listTypes,
    isGlobal: file.data.isGlobal,
  });

  const handleChangeDatabases = (databases: DatabaseModel[]) => {
    setSelectedDatabases(databases.map(({ name }) => name));
  };

  const isExistSuppressionDatabase = selectedDatabases?.some(
    (database) => database === "suppression",
  );

  const databasesAutocomplete = useAutocomplete(getDatabasesNames, "name", "name");

  const onSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.headerValues,
        databases: values.databases,
        listTypes: values.listTypes,
        categories: values.categories,
        isGlobal: values.isGlobal,
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose} contentClassName={styles.modal}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        <FormField
          type="select"
          name="databases"
          label="Databases"
          placeholder="Databases"
          asyncOptions={databasesAutocomplete}
          onChange={handleChangeDatabases}
          highlightKeys={["suppression"]}
          isMulti
          canSelectAll
          dropdownPositionCalculationDeps={[isExistSuppressionDatabase]}
        />
        {isExistSuppressionDatabase && <ListTypeSection />}
        <LocalFormField
          type="file-table"
          name="headerValues"
          label="Select Email column"
          parsedFile={parsedFile}
          loading={loading}
        />
        <FormButtons>
          <Button appearance="secondary" onClick={setClose}>
            Cancel
          </Button>
          <Button type="submit">Set values</Button>
        </FormButtons>
      </Form>
    </Modal>
  );
};

export { SuppressModalForm };
