import { array, boolean, object, SchemaOf, string, AnySchema } from "yup";

import { fileValidation, sanitizeFileName } from "utils";
import { minOneFieldText, requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { Values, DomainPurifyFileData } from "./domain-purify.types";

export const supportedFormats = [csvFormat, txtFormat].flat();

export const validationSchema: SchemaOf<Values> = object({
  useFile: boolean().required(requiredFieldText),
  match: string().required(requiredFieldText),
  domains: string().when("useFile", {
    is: (val: boolean) => !val,
    then: string().min(1, minOneFieldText).required(requiredFieldText),
    otherwise: string(),
  }) as AnySchema,
  domainFormFile: array().when("useFile", {
    is: (val: boolean) => val,
    then: fileValidation(),
    otherwise: array().nullable(),
  }) as AnySchema,
});

export const getInitialData = (item: File): DomainPurifyFileData => ({
  fileName: sanitizeFileName(item.name),
  useFile: false,
  match: false,
  domains: [],
  domainFile: false,
  domainFormFile: undefined,
});

export const matchOptions = [
  { value: "remove", label: "Remove matching rows" },
  { value: "get", label: "Get matching rows" },
];
