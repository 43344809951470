import { useHistory } from "react-router-dom";
import { useState } from "react";
import { FormikHelpers } from "formik";
import { useDidMount, useDidUpdate, useFetch, useSubmit, useQuery } from "@epcnetwork/core-ui-kit";

import { usePayload } from "hooks";
import { LOGIN_PAGE, FORGOT_PASSWORD_PAGE } from "constants/routes.constants";
import { isTokenValid, changePassword } from "api";
import { Values } from "./reset-password.types";

interface Output {
  onSubmit: (values: Values, helpers: FormikHelpers<Values>) => Promise<void>;
  userEmail: string;
  errorMessage: string;
}

const useResetPasswordHook = (): Output => {
  const history = useHistory();
  const { query } = useQuery();

  const [userEmail, setUserEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const queryValue = String(query.key || "");

  useDidMount(() => {
    if (!queryValue) {
      moveToSendEmail();
    }
  });

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(
    changePassword.setParams({ key: queryValue }),
  );
  const apiResponse = useFetch(isTokenValid.setParams({ key: queryValue }));
  const { payload, error } = usePayload(apiResponse);

  useDidUpdate(() => {
    if (payload && payload.email && !userEmail) {
      setUserEmail(payload.email);
    }

    if (error) {
      moveToSendEmail();
    }
  }, [payload, error]);

  onSubmitSuccess(() => {
    moveToLogin();
  });

  onSubmitError((error) => {
    setErrorMessage(error.message);
  });

  const moveToSendEmail = () => history.push(`${FORGOT_PASSWORD_PAGE.path}?expired=true`);

  const moveToLogin = () => history.push(`${LOGIN_PAGE.path}?changed=true`);

  return {
    onSubmit,
    userEmail,
    errorMessage,
  };
};

export { useResetPasswordHook };
