import { useState, useRef, FC, KeyboardEvent } from "react";
import {
  Button,
  notification,
  FormButtons,
  useAutocomplete,
  FormField,
  useSubmit,
  useFormValues,
  Form,
  MessageField,
  NonNullableKeys,
} from "@epcnetwork/core-ui-kit";

import { postUser, putUser, getUser, getGroups } from "api";
import { UserFromProps, Values } from "./users-form.types";
import { initialData, roles, validationSchema } from "./users-form.constants";

import styles from "./users-form.module.css";

const UsersForm: FC<UserFromProps> = ({ id, handleItemChange, handleCloseModal }) => {
  const [error, setError] = useState("");
  const { formProps, mapInitialValues } = useFormValues(
    initialData,
    getUser.setParams({ userId: id as string }),
    !!id,
  );
  const { onSubmitMapping, onSubmitSuccess, onSubmitError } = useSubmit(
    postUser,
    putUser.setParams({ userId: id as string }),
    !!id,
  );

  mapInitialValues((payload) => {
    return {
      ...payload,
      email: payload.email,
      password: "",
      passwordConfirm: "",
      userGroup: payload.userGroup.id,
      roles: payload.roles[0],
      isEdit: !!id,
    };
  });

  onSubmitSuccess((payload) => {
    handleItemChange(payload);
    const title = id ? "User edited" : "User added";
    const subtitle = id ? "Successfully edited user" : "Successfully added user";
    notification.success(title, subtitle);
  });

  onSubmitError((error) => {
    setError(error.message);
  });

  const onSubmit = onSubmitMapping((values: NonNullableKeys<Values>) => {
    if (id) {
      return {
        userGroup: values.userGroup,
        email: values.email,
      };
    }

    return {
      ...values,
      userGroup: values.userGroup,
    };
  });

  const groupsAutocomplete = useAutocomplete(getGroups, "id", "name");

  const inputRef = useRef<(HTMLInputElement & HTMLTextAreaElement) | null>(null);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Tab") {
      e.preventDefault();
      inputRef.current?.focus();
    }
  };

  return (
    <div className={styles.container}>
      <Form {...formProps} onSubmit={onSubmit} validationSchema={validationSchema(id)}>
        <div className={styles.title}>User Form</div>
        <MessageField message={error} />
        <div className={styles.row}>
          <FormField type="text" name="email" label="Email" placeholder="name@email.com" required />
          {!id && (
            <FormField
              type="select"
              name="roles"
              label="Role"
              placeholder="Role"
              options={roles}
              required
            />
          )}
          {!id && (
            <FormField
              type="password"
              name="password"
              placeholder="********"
              label="Password"
              required
              onKeyDown={handleKeyPress}
            />
          )}
          <FormField
            type="select"
            name="userGroup"
            label="Groups"
            placeholder="Groups"
            asyncOptions={groupsAutocomplete}
            required
          />
          {!id && (
            <FormField
              type="password"
              name="confirmPassword"
              placeholder="********"
              label="Confirm Password"
              required
              forwardRef={inputRef}
            />
          )}
        </div>
        <FormButtons>
          <Button type="button" onClick={handleCloseModal} appearance="secondary">
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </FormButtons>
      </Form>
    </div>
  );
};

export { UsersForm };
