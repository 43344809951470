import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  Button,
  Modal,
  FormButtons,
  Select,
  FormField,
  SelectOption,
  Nullable,
} from "@epcnetwork/core-ui-kit";

import { SplitFileItem, ValuesSize, ValuesLines } from "../split.types";
import {
  validationSchemaLines,
  validationSchemaSize,
  options,
  optionsSizes,
} from "../split.constants";

import closeImg from "assets/images/close-default.svg";
import styles from "../split.module.css";

type SplitModalFormProps = {
  file: SplitFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: SplitFileItem) => void;
};

const SplitModalForm: FC<SplitModalFormProps> = ({ file, setClose, onSubmitClick }) => {
  const [formType, setFormType] = useState<Nullable<SelectOption>>(
    file.data.lines ? options[0] : options[1],
  );
  const [initialValuesLines] = useState<ValuesLines>({
    lines: file.data.lines || 1,
  });
  const [initialValuesSize] = useState<ValuesSize>({
    size: Number(file.data.size?.slice?.(0, -2) || 1),
    sizeUnit: file.data.size?.slice?.(-2) || optionsSizes[0].value,
  });

  const onSubmitLines = (values: ValuesLines, { setSubmitting }: FormikHelpers<ValuesLines>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: { ...file.data, lines: values.lines, size: undefined },
    });
    setSubmitting(false);
  };

  const onSubmitSize = (values: ValuesSize, { setSubmitting }: FormikHelpers<ValuesSize>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: { ...file.data, size: String(values.size) + values.sizeUnit, lines: undefined },
    });
    setSubmitting(false);
  };

  const handleChangeSelect = (option: Record<string, string>) => {
    if (!option) {
      setFormType(null);
      return;
    }

    setFormType({ value: option.value, label: option.label });
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose}>
      <h2>Set values {file.originalFile.name}</h2>
      <div className={styles.header}>
        <img src={closeImg} alt="" onClick={setClose} width={10} height={10} />
      </div>
      <Select
        label="Split by"
        selectedOptionsKeys={formType?.value}
        options={options}
        disableClearing
        disableSingleOptionUncheck
        onChange={(option) => handleChangeSelect(option)}
      />
      {formType?.value === options[0].value && (
        <Form
          enableReinitialize
          initialValues={initialValuesLines}
          onSubmit={onSubmitLines}
          validationSchema={validationSchemaLines}
        >
          <FormField type="number" name="lines" label="Values" />
          <FormButtons>
            <Button appearance="secondary" onClick={setClose}>
              Cancel
            </Button>
            <Button type="submit">Set values</Button>
          </FormButtons>
        </Form>
      )}
      {formType?.value === options[1].value && (
        <Form
          enableReinitialize
          initialValues={initialValuesSize}
          onSubmit={onSubmitSize}
          validationSchema={validationSchemaSize}
        >
          <FormField type="number" name="size" label="Value" />
          <FormField type="select" name="sizeUnit" label="Unit" options={optionsSizes} />
          <FormButtons>
            <Button appearance="secondary" onClick={setClose}>
              Cancel
            </Button>
            <Button type="submit">Set values</Button>
          </FormButtons>
        </Form>
      )}
    </Modal>
  );
};

export { SplitModalForm };
