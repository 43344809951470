import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  Form,
  Modal,
  FormField,
  MessageField,
  FormButtons,
  Button,
  useAutocomplete,
  useFileParser,
  Nullable,
  SelectOption,
} from "@epcnetwork/core-ui-kit";

import { ListTypeModel } from "models";
import { FormField as LocalFormField } from "components";
import {
  getESPs,
  getSuppressionCategories,
  getSuppressionCleaningServicesStatuses,
  getSuppressionListTypeSimple,
} from "api";
import { SuppressionImportItem, Values } from "../suppression-import.types";
import { validationSchema } from "../suppression-import.constants";
import { suppressionTypes, suppressionKindOptions } from "../../form/suppression-form.constants";

import styles from "../suppression-import.module.css";

type FormModalProps = {
  file: SuppressionImportItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: SuppressionImportItem) => void;
};

const FormModal: FC<FormModalProps> = ({ file, setClose, onSubmitClick }) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);
  const [selectedListType, setSelectedListType] = useState<Nullable<ListTypeModel[]>>(null);
  const [selectedKind, setSelectedKind] = useState("");

  const [initialValues] = useState<Values>({
    headerValues: {
      hasHeaders: file.data.hasHeaders,
      email: file.data.email,
      headers: file.data.headers,
    },
    category: file.data.category,
    cleaningServiceStatus: file.data.cleaningServiceStatus,
    esp: file.data.esp,
    listType: file.data.listType,
    type: file.data.type,
  });

  const espAutocomplete = useAutocomplete(getESPs, "id", "name");
  const listTypeAutocomplete = useAutocomplete(getSuppressionListTypeSimple, "id", "name");
  const categoriesAutocomplete = useAutocomplete(getSuppressionCategories, "id", "name");
  const cleaningServicesStatusesAutocomplete = useAutocomplete(
    getSuppressionCleaningServicesStatuses,
    "id",
    "name",
  );

  const handleChangeListType = (listType: ListTypeModel[]) => {
    setSelectedListType(listType);
  };

  const handleChangeListKind = (option: SelectOption) => {
    if (option) return setSelectedKind(option.value as string);
    setSelectedKind("");
  };

  const mapOptions = (
    options: (typeof listTypeAutocomplete)["fetchOptions"],
  ): (typeof listTypeAutocomplete)["fetchOptions"] => {
    const kind = selectedKind ? selectedKind === "true" : "";

    return options.filter((opt) => (selectedKind ? opt.isGlobal === kind : options));
  };

  const onSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.headerValues,
        category: values.category,
        cleaningServiceStatus: values.cleaningServiceStatus,
        esp: values.esp,
        listType: values.listType,
        type: values.type,
      },
    });
    setSubmitting(false);
  };

  const disableClearing = selectedListType?.filter((list) => list.locked);

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose}>
      <h2>
        Set values
        {file.originalFile.name}
      </h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        <div className={styles.row}>
          <FormField
            type="select"
            name="type"
            label="Type"
            placeholder="Choose type from the list"
            options={suppressionTypes}
            isSearchable={false}
            required
          />
          <FormField
            type="select"
            name="category"
            label="Category"
            placeholder="Choose Category(ies) from the list"
            asyncOptions={categoriesAutocomplete}
            isSearchable={false}
            isMulti
          />
          <FormField
            type="select"
            name="suppressionKind"
            label="Suppression Kind"
            placeholder="Choose Suppression Kind"
            options={suppressionKindOptions}
            onChange={handleChangeListKind}
          />
          <FormField
            type="select"
            name="esp"
            label="Esp"
            placeholder="Choose ESP(s) from the list"
            asyncOptions={espAutocomplete}
            isSearchable={false}
            isMulti
          />
          <FormField
            type="select"
            name="listType"
            label="List type"
            placeholder="Select from the list"
            asyncOptions={{
              ...listTypeAutocomplete,
              fetchOptions: mapOptions(listTypeAutocomplete.fetchOptions),
            }}
            disableClearing={!!disableClearing?.length}
            onChange={handleChangeListType}
            isMulti
            isSearchable
            required
          />
        </div>
        <FormField
          type="select"
          name="cleaningServiceStatus"
          label="Email list verification service status"
          placeholder="Choose ELVSS from the list"
          asyncOptions={cleaningServicesStatusesAutocomplete}
          isSearchable={false}
        />
        <LocalFormField
          type="file-table"
          name="headerValues"
          label="Select Email column"
          parsedFile={parsedFile}
          loading={loading}
        />
        <FormButtons>
          <Button appearance="secondary" onClick={setClose}>
            Cancel
          </Button>
          <Button type="submit">Set values</Button>
        </FormButtons>
      </Form>
    </Modal>
  );
};

export { FormModal };
