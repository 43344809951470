import { Route, Redirect } from "react-router-dom";
import { RouteComponentProps, StaticContext } from "react-router";
import { useSelector } from "react-redux";
import { FC, ReactNode } from "react";

import { RootState } from "store";
import { TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { RouteConfig } from "config/routes.config";

type AuthRouteProps = {
  render?:
    | ((
        props: RouteComponentProps<{ [x: string]: string | undefined }, StaticContext, unknown>,
      ) => ReactNode)
    | undefined;
} & Omit<RouteConfig, "component" | "showNavigation">;

const AuthRoute: FC<AuthRouteProps> = ({ ...rest }) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const isValidRoute = localStorage.getItem(TOKEN_STORAGE_FIELD) && user;

  if (!isValidRoute) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  return <Route {...rest} />;
};

export { AuthRoute };
