import { UseFiltersProps } from "@epcnetwork/core-ui-kit";

import { FiltersType } from "types";
import { searchMinLength } from "constants/form.constants";

export const TABLE_COLUMNS = [
  { label: "Email" },
  { label: "Roles" },
  { label: "Group" },
  { label: "Created at" },
  { label: "" },
];

export const filtersOptions: UseFiltersProps<FiltersType> = {
  searchOptions: { searchKey: "name", searchMinLength },
};
