import { number, object, SchemaOf, string } from "yup";

import { sanitizeFileName } from "utils";
import { minOneFieldText, requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { ValuesLines, ValuesSize, SplitFileDataSize, SplitFileDataLines } from "./split.types";

export const supportedFormats = [csvFormat, txtFormat].flat();
export const validationSchemaLines: SchemaOf<ValuesLines> = object({
  lines: number().min(1, minOneFieldText).required(requiredFieldText),
});

export const validationSchemaSize: SchemaOf<ValuesSize> = object({
  size: number().min(1, minOneFieldText).required(requiredFieldText),
  sizeUnit: string().required(requiredFieldText),
});

export const options = [
  { value: "lines", label: "Lines number" },
  { value: "size", label: "File size" },
];

export const optionsSizes = [
  { value: "GB", label: "GB" },
  { value: "MB", label: "MB" },
  { value: "KB", label: "KB" },
];

export const getInitialData = (item: File): SplitFileDataSize | SplitFileDataLines => ({
  fileName: sanitizeFileName(item.name),
});
