import { FC } from "react";
import { useFormikContext } from "formik";
import cn from "classnames";
import { FormField, ArrayElement } from "@epcnetwork/core-ui-kit";

import { RoleEntitiesOptionType, RoleFormValues, RoleEntitiesType } from "../roles-form.types";

import globalStyles from "assets/styles/global.module.css";
import styles from "../roles-form.module.css";

type FormTableProps = {
  options: RoleEntitiesOptionType[];
  permissionAll: RoleEntitiesType["permissionAll"];
  headKeys: string[];
  isAccess?: boolean;
};

const FormTable: FC<FormTableProps> = ({ options, permissionAll, isAccess, headKeys }) => {
  const { values } = useFormikContext<RoleFormValues>();
  const hasFullAccess =
    (Object.keys(values.roles).includes(permissionAll) && headKeys.includes(permissionAll)) ||
    undefined ||
    Object.keys(values.roles).includes("ADMIN");

  const showActionCheckbox = (
    option: RoleEntitiesOptionType,
    action: ArrayElement<RoleEntitiesOptionType["actions"]>,
  ) => {
    return option.actions.includes(action);
  };

  const stylesForFullAccess = cn({ [styles.checkBoxChecked]: hasFullAccess });

  return (
    <div className={`${styles.responsiveTable} ${globalStyles.addScrollStyles}`}>
      <table className={styles.roleTable}>
        <thead>
          {isAccess && (
            <tr>
              <td>Section</td>
              <td>Access</td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          )}
          {!isAccess && (
            <tr>
              <td>Section</td>
              <td>Read</td>
              <td>List Read</td>
              <td>Create</td>
              <td>Update</td>
              <td>Delete</td>
            </tr>
          )}
        </thead>
        <tbody>
          {isAccess &&
            options.map((option) => (
              <tr key={option.name}>
                <td>{option.name}</td>
                <td>
                  <FormField
                    disabled={hasFullAccess}
                    type="checkbox"
                    name={`roles[${option.permission}]`}
                    className={stylesForFullAccess}
                  />
                </td>
              </tr>
            ))}
          {!isAccess &&
            options.map((option) => {
              return (
                <tr key={option.name}>
                  <td>{option.name}</td>
                  <td>
                    {showActionCheckbox(option, "read") && (
                      <FormField
                        disabled={hasFullAccess}
                        type="checkbox"
                        name={`roles[${option.permission}_R]`}
                        className={stylesForFullAccess}
                      />
                    )}
                  </td>
                  <td>
                    {showActionCheckbox(option, "read-list") && (
                      <FormField
                        disabled={hasFullAccess}
                        type="checkbox"
                        name={`roles[${option.permission}_LIST_R]`}
                        className={stylesForFullAccess}
                      />
                    )}
                  </td>
                  <td>
                    {showActionCheckbox(option, "create") && (
                      <FormField
                        disabled={hasFullAccess}
                        type="checkbox"
                        name={`roles[${option.permission}_C]`}
                        className={stylesForFullAccess}
                      />
                    )}
                  </td>
                  <td>
                    {showActionCheckbox(option, "update") && (
                      <FormField
                        disabled={hasFullAccess}
                        type="checkbox"
                        name={`roles[${option.permission}_U]`}
                        className={stylesForFullAccess}
                      />
                    )}
                  </td>
                  <td>
                    {showActionCheckbox(option, "delete") && (
                      <FormField
                        disabled={hasFullAccess}
                        type="checkbox"
                        name={`roles[${option.permission}_D]`}
                        className={stylesForFullAccess}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export { FormTable };
