import { useHistory } from "react-router-dom";
import { FC } from "react";

import { DEFAULT_PAGE } from "constants/routes.constants";

import logoImg from "assets/images/logo.svg";
import styles from "../sidebar.module.css";

type LogoProps = {
  isCollapsed: boolean;
};

const Logo: FC<LogoProps> = ({ isCollapsed }) => {
  const history = useHistory();

  const handleRedirectHome = () => {
    history.push(DEFAULT_PAGE.path);
  };

  return (
    <div className={styles.logo} onClick={handleRedirectHome}>
      <img src={logoImg} alt="" width={36} height={36} />
      {!isCollapsed && <span>Mergex</span>}
    </div>
  );
};

export { Logo };
