import { useHistory } from "react-router-dom";
import { FC, useCallback, useState } from "react";
import cn from "classnames";
import {
  FileItem,
  FileUpload,
  Button,
  Container,
  useFileQueue,
  DropAcceptedFunc,
  Nullable,
  useDidUpdate,
  notification,
} from "@epcnetwork/core-ui-kit";

import { getAcceptedFilesText, getFileUuidName, getSupportedFormats, onFileRedirect } from "utils";
import { useAuthorization } from "hooks";
import { SUPPRESSION_LIST_PAGE, SUPPRESSION_IMPORT_PAGE } from "constants/routes.constants";
import { SUPPRESSION_PERMISSION } from "constants/roles.constants";
import { PostSuppressionFileFormData } from "api/suppression/suppression.interfaces";
import { postSuppressionFile } from "api";
import { SuppressionImportItem } from "./suppression-import.types";
import {
  getInitialData,
  supportedFormats,
  suppressionEntity,
} from "./suppression-import.constants";
import { FormModal } from "./components/form-modal";

import styles from "./suppression-import.module.css";

const SuppressionImportPage: FC = () => {
  const [editedFile, setEditedFile] = useState<Nullable<SuppressionImportItem>>(null);
  const history = useHistory();
  const ability = useAuthorization();

  const isAuthorized =
    SUPPRESSION_IMPORT_PAGE.auth && SUPPRESSION_PERMISSION.name
      ? ability.can("create", SUPPRESSION_PERMISSION.name)
      : true;

  const {
    files,
    createInitialFile,
    addFiles,
    getItem,
    updateFiles,
    removeFiles,
    clearEntity,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitOneByOne,
  } = useFileQueue<SuppressionImportItem, PostSuppressionFileFormData>(
    suppressionEntity,
    postSuppressionFile,
  );

  useDidUpdate(() => {
    if (!isAuthorized || !isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const handleConfigureItem = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: SuppressionImportItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleCloseModal = useCallback(() => setEditedFile(null), []);

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, page: SUPPRESSION_LIST_PAGE, history });
  };

  const handleAcceptedDrop: DropAcceptedFunc = (acceptedFiles) => {
    addFiles(acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })));
  };

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleRedirectToSuppression = () => {
    history.push(SUPPRESSION_LIST_PAGE.path);
  };

  const handleSubmit = () => {
    const data = files.map((file) => {
      const formData = new FormData();
      formData.append("fileInfo", JSON.stringify(file.data));
      formData.append("file", file.originalFile, getFileUuidName(file));

      return {
        id: file.id,
        data: formData,
      };
    });

    submitOneByOne(data);
  };

  return (
    <Container>
      <div className={styles.container}>
        <div className={styles.title}>Import</div>
        <div className={styles.subtitle}>Upload files and set values for each file</div>
        <div className={styles.form}>
          <FileUpload
            className={cn(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleConfigureItem}
                onEditValuesClick={handleConfigureItem}
              />
            ))}
          </div>
          {editedFile && (
            <FormModal
              file={editedFile}
              setClose={handleCloseModal}
              onSubmitClick={handleModalSubmit}
            />
          )}
          <div className={styles.buttons}>
            <Button
              appearance="secondary"
              onClick={!files.length ? handleRedirectToSuppression : handleClearClick}
            >
              Cancel
            </Button>
            {!isEntityFinished && (
              <Button
                onClick={handleSubmit}
                disabled={!hasAllConfigured || !files.length}
                loading={!isEntityInConfiguration}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export { SuppressionImportPage };
