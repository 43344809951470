import { FC } from "react";
import { useFormikContext } from "formik";
import { Modal, Input, Button, MessageField } from "@epcnetwork/core-ui-kit";

import { Hint } from "components";

import styles from "../../suppression-form.module.css";

type PopupProps = {
  value: string;
  error: string;
  isOpen: boolean;
  setClose: VoidFunction;
};

const Popup: FC<PopupProps> = ({ error, value, isOpen, setClose }) => {
  const { submitForm } = useFormikContext();
  const isDisabled = !value || !!error;

  return (
    <Modal isOpen={isOpen} setClose={setClose} contentClassName={styles.modal}>
      <MessageField message={error} className={`${styles.errorMsg} ${styles.errorMsgPopup}`} />
      <h2 className={styles.titlePopup}>Are you sure?</h2>
      <Hint>
        Please, make sure the value is correct. <br />
        You won’t be able to change the value after saving.
      </Hint>
      <Input value={value} label="Value" disabled className={styles.input} />
      <div className={styles.buttonsWrapper}>
        <Button appearance="secondary" onClick={setClose} className={styles.button}>
          Back
        </Button>
        <Button
          appearance="primary"
          onClick={submitForm}
          disabled={isDisabled}
          className={styles.button}
        >
          Yes,suppress it
        </Button>
      </div>
    </Modal>
  );
};

export { Popup };
