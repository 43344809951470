import { useSelector } from "react-redux";
import { FC, useState } from "react";
import {
  useCall,
  notification,
  Menu,
  MenuButton,
  TableCell,
  TableRow,
} from "@epcnetwork/core-ui-kit";

import { RootState } from "store";
import { getSuppressionListKind } from "pages/suppression/list/suppression-list.constants";
import { GroupModel, ListTypeModel } from "models";
import { ADMIN_PERMISSION } from "constants/roles.constants";
import { deleteSuppressionListType } from "api";
import { deletingMessage } from "../suppression-settings.constants";

import styles from "./table-row.module.css";

type RowProps = {
  item: ListTypeModel;
  refresh: VoidFunction;
  handleEdit: (id: string) => void;
  handleSetValue: (value: string) => void;
  handleOpenDeleteModal: VoidFunction;
};

const Row: FC<RowProps> = ({
  item,
  refresh,
  handleEdit,
  handleSetValue,
  handleOpenDeleteModal,
}) => {
  const [isDeleted, setIsDeleted] = useState(false);
  const { submit, onCallError, onCallSuccess } = useCall(deleteSuppressionListType);
  const { user } = useSelector((state: RootState) => state.auth);
  const isAdmin = user?.roles.includes(ADMIN_PERMISSION);
  const { confirm, success, error } = deletingMessage;

  const handleItemDelete = () => {
    if (item.isGlobal && !isAdmin) {
      handleSetValue(item.name);
      handleOpenDeleteModal();
      return;
    }

    notification.confirm(confirm.title, confirm.message, {
      onOk: () => {
        setIsDeleted(true);
        submit({ params: { id: item.id } });
      },
      okText: "Delete",
      icon: "delete",
    });
  };

  const handleItemEdit = () => {
    handleEdit(item.id);
  };

  onCallSuccess(() => {
    setIsDeleted(true);
    refresh();
    notification.success(success.title, success.message);
  });

  onCallError(() => {
    setIsDeleted(false);
    notification.error(error.title, error.message);
  });

  if (isDeleted) {
    return null;
  }

  return (
    <TableRow id={item.id}>
      <TableCell>
        <div className={styles.value}>{item.name}</div>
      </TableCell>
      <TableCell>
        <div className={styles.values}>{item.amount || "0"}</div>
      </TableCell>
      <TableCell>
        <div className={styles.value}>{(item.userGroup as GroupModel)?.name || "-"}</div>
      </TableCell>
      <TableCell>
        <div className={styles.values}>{item.user?.email || "-"}</div>
      </TableCell>
      <TableCell>
        <div className={styles.values}>{getSuppressionListKind(item.isGlobal)}</div>
      </TableCell>
      <TableCell>
        <Menu>
          <MenuButton onClick={handleItemEdit} icon="edit">
            Edit
          </MenuButton>
          <MenuButton
            appearance="delete"
            icon="delete"
            onClick={handleItemDelete}
            disabled={item.isProtected}
          >
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export { Row };
