import { List } from "@epcnetwork/core-ui-kit";

import {
  SuppressionModel,
  TagModel,
  SuppressionCategoryModel,
  CleaningServiceStatusModel,
  ListTypeModel,
} from "models";
import { apiMiddleware } from "../api.middleware";
import {
  PostSuppressionFormData,
  PostSuppressionFileFormData,
  PostSuppressionSettingsData,
  DeleteSuppressionData,
} from "./suppression.interfaces";

export const getSuppressions = apiMiddleware<List<SuppressionModel>>()({
  method: "get",
  endpoint: "/suppression/list",
});

export const getSuppressionsExport = apiMiddleware<List<SuppressionModel>>()({
  method: "get",
  endpoint: "/suppression/export",
});

export const getSuppression = apiMiddleware<SuppressionModel>()({
  method: "get",
  endpoint: "/suppression/list/:suppressionId",
});

export const getSuppressionTags = apiMiddleware<TagModel[]>()({
  method: "get",
  endpoint: "/suppression/tag",
});

export const getSuppressionListTypeSimple = apiMiddleware<List<ListTypeModel>>()({
  method: "get",
  endpoint: "/suppression/listType",
});

export const getSuppressionListType = apiMiddleware<List<ListTypeModel>>()({
  method: "get",
  endpoint: "/suppression/list-type",
});

export const getSuppressionListTypeById = apiMiddleware<ListTypeModel>()({
  method: "get",
  endpoint: "/suppression/list-type/:id",
});

export const getSuppressionCategories = apiMiddleware<SuppressionCategoryModel[]>()({
  method: "get",
  endpoint: "/suppression/category",
});

export const getSuppressionCleaningServicesStatuses = apiMiddleware<CleaningServiceStatusModel[]>()(
  {
    method: "get",
    endpoint: "/suppression/cleaningServiceStatus",
  },
);

export const postSuppression = apiMiddleware<SuppressionModel, PostSuppressionFormData>()({
  method: "post",
  endpoint: "/suppression/list",
});

export const putSuppression = apiMiddleware<SuppressionModel, PostSuppressionFormData>()({
  method: "put",
  endpoint: "/suppression/list/:id",
});

export const postSuppressionFile = apiMiddleware<null, PostSuppressionFileFormData>()({
  method: "post",
  endpoint: "/suppression/file/insert",
  isFormData: true,
});

export const postSuppressionSettings = apiMiddleware<ListTypeModel, PostSuppressionSettingsData>()({
  method: "post",
  endpoint: "/suppression/list-type",
});

export const putSuppressionSettings = apiMiddleware<ListTypeModel, PostSuppressionSettingsData>()({
  method: "put",
  endpoint: "/suppression/list-type/:id",
});

export const deleteSuppression = apiMiddleware<null, DeleteSuppressionData>()({
  method: "delete",
  endpoint: "/suppression/list/:suppressionId",
});

export const deleteSuppressionListType = apiMiddleware<null>()({
  method: "delete",
  endpoint: "/suppression/list-type/:id",
});
