import { useHistory } from "react-router-dom";
import { FC, memo, useCallback, useRef } from "react";
import cn from "classnames";
import {
  Button,
  Container,
  DropAcceptedFunc,
  notification,
  useWillUnmount,
  useFileQueue,
  useDidUpdate,
  FileItem,
  FileUpload,
} from "@epcnetwork/core-ui-kit";

import { getPipelineRoute } from "utils/pipelines.utils";
import { getAcceptedFilesText, getFileUuidName, getSupportedFormats, onFileRedirect } from "utils";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { postConvertFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { ConvertFileItem, PipelineConvertData } from "./convert.types";
import { getInitialData, supportedFormats } from "./convert.constants";

import styles from "./convert.module.css";

const ConvertPage: FC = memo(() => {
  const componentIsMounted = useRef(true);
  useWillUnmount(() => (componentIsMounted.current = false));
  const history = useHistory();

  const pipeline = getPipelineRoute("Convert");

  const {
    files,
    createInitialFile,
    addFiles,
    removeFiles,
    clearEntity,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllSuccessFiles,
    submitOneByOne,
  } = useFileQueue<ConvertFileItem, PipelineConvertData>(pipeline.title, postConvertFile);

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })),
      );
    },
    [addFiles, createInitialFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleSubmit = () => {
    const data = files.map((file) => {
      const formData = new FormData();
      formData.append("fileInfo", JSON.stringify(file.data));
      formData.append("file", file.originalFile, getFileUuidName(file));

      return {
        id: file.id,
        data: formData,
      };
    });

    submitOneByOne(data);
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, history });
  };

  const handleRedirectToPipelines = () => {
    history.push(PIPELINES_LIST_PAGE.path);
  };

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />

        <div className={styles.form}>
          <FileUpload
            className={cn(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                isTuned
              />
            ))}
          </div>
          <div className={styles.buttons}>
            <Button
              appearance="secondary"
              onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
              disabled={isEntityFinished && !hasAllSuccessFiles}
            >
              Cancel
            </Button>
            {!isEntityFinished && (
              <Button
                onClick={handleSubmit}
                disabled={!files.length}
                loading={!isEntityInConfiguration}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
});

export { ConvertPage };
