import { UserModel } from "models";
import { apiMiddleware } from "../api.middleware";
import { UserFormData, UserRolesFormData } from "./users.interface";

export const getUsers = apiMiddleware<UserModel[]>()({
  method: "get",
  endpoint: "/users",
});

export const getUser = apiMiddleware<UserModel>()({
  method: "get",
  endpoint: "/users/:userId",
});

export const postUser = apiMiddleware<UserModel, UserFormData>()({
  method: "post",
  endpoint: "/users",
});

export const putUser = apiMiddleware<UserModel, UserFormData>()({
  method: "put",
  endpoint: "/users/:userId",
});

export const patchUser = apiMiddleware<UserModel, UserFormData>()({
  method: "patch",
  endpoint: "/users/:userId",
});

export const putUserRoles = apiMiddleware<UserModel, UserRolesFormData>()({
  method: "put",
  endpoint: "/users/roles/:userId",
});

export const deleteUser = apiMiddleware<UserModel>()({
  method: "delete",
  endpoint: "/users/:userId",
});
