import { FC, useState } from "react";
import {
  MessageField,
  Table,
  TableCell,
  useFetch,
  TableRow,
  Button,
  useCall,
  notification,
  Loader,
} from "@epcnetwork/core-ui-kit";

import { GroupModel, ListTypeModel } from "models";
import { usePayload, useShowContent } from "hooks";
import { deleteSuppression, getSuppression } from "api";
import { tableColumns } from "./delete-modal.constants";

import styles from "./delete-modal.module.css";

type DeleteModalProps = {
  email: string;
  deleteId: string;
  refresh: VoidFunction;
  closeModal: VoidFunction;
};

const DeleteModal: FC<DeleteModalProps> = ({ deleteId, email, closeModal, refresh }) => {
  const [error, setError] = useState("");
  const [selectedItems, setSelectedItems] = useState<ListTypeModel[]>([]);
  const apiResponse = useFetch(getSuppression.setParams({ suppressionId: deleteId }));
  const { payload } = usePayload(apiResponse);
  const { showContent, showLoader } = useShowContent(apiResponse);
  const { submit, onCallError, onCallSuccess } = useCall(deleteSuppression);

  const selectedIds = selectedItems.map((item) => item.id);

  const handleSelectItems = (items: ListTypeModel[]) => {
    setSelectedItems(items);
  };

  const handleDeleteItems = () => {
    submit({ params: { suppressionId: deleteId }, data: { listType: selectedIds } });
  };

  onCallSuccess(() => {
    refresh();
    closeModal();
    setError("");
    notification.success("Suppression deleted", "Successfully deleted suppression");
  });

  onCallError((msg) => {
    setError(msg.message);
  });

  return (
    <div>
      <MessageField message={error} className={styles.errorMsg} />
      <div className={styles.title}>Are you sure you want to delete the email?</div>
      <div className={styles.subtitle}>
        Email <span className={styles.value}>{email}</span> is on a few suppression list. <br />
        Please, choose from which of this list delete this email:
      </div>
      {showLoader && (
        <Loader type="puff-loader" position="absolute" loaderClassName={styles.loader} />
      )}
      {showContent && (
        <>
          <div className={styles.tableTitle}>Suppression Lists</div>
          <div className={styles.table}>
            <Table
              {...apiResponse}
              error={apiResponse.error?.message}
              list={payload.listType}
              multiSelect
              itemIdKey="id"
              selectedList={selectedItems}
              columns={tableColumns}
              onSelect={handleSelectItems}
              contentClassName={styles.content}
              row={(item) => (
                <TableRow id={item.id}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell className={styles.cell}>
                    {(item.userGroup as GroupModel)?.name}
                  </TableCell>
                </TableRow>
              )}
            />
          </div>
        </>
      )}
      <div className={styles.buttons}>
        <Button appearance="secondary" onClick={closeModal} className={styles.button}>
          Back
        </Button>
        <Button
          disabled={!selectedIds.length}
          className={styles.button}
          onClick={handleDeleteItems}
        >
          Yes, delete
        </Button>
      </div>
    </div>
  );
};

export { DeleteModal };
