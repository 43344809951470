import { RouteComponentProps } from "react-router-dom";
import { FC } from "react";
import { Button } from "@epcnetwork/core-ui-kit";

import { LOGOUT_PAGE } from "constants/routes.constants";

import styles from "../navbar.module.css";

type MenuProps = { history: RouteComponentProps["history"] };

const Menu: FC<MenuProps> = ({ history }) => {
  const handleLogout = () => history.push(LOGOUT_PAGE.path);

  return (
    <div className={styles.menu} data-testid="navbar-menu">
      <Button
        className={styles.item}
        onClick={handleLogout}
        appearance="transparent"
        btnSize="small"
      >
        Log out
      </Button>
    </div>
  );
};

export { Menu };
