const appEnv = process.env.REACT_APP_ENV || "development";

interface Environment {
  apiUrl: string;
}

interface Environments {
  [key: string]: Environment;
}

const environments: Environments = {
  development: {
    apiUrl: process.env.REACT_APP_API || "http://localhost:3001",
  },
  test: {
    apiUrl: process.env.REACT_APP_API || "https://apidev.mergex.dev",
  },
  staging: {
    apiUrl: process.env.REACT_APP_API || "https://api.mergex.dev",
  },
  prod: {
    apiUrl: "",
  },
};

export const appEnvironment: Environment = environments[appEnv];
