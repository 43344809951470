import { FiltersType, QueryParams, NotificationProps } from "./suppression-settings.types";

export const tableColumns = [
  { label: "List name", queryKey: "name" },
  { label: "Number of items" },
  { label: "Group (Creator)", queryKey: "userGroup" },
  { label: "Creator", queryKey: "user" },
  { label: "Kind", queryKey: "isGlobal" },
  { label: "" },
];

export const queryKeys = tableColumns.map(({ queryKey }) => `order[${queryKey}]`);
export const nonClearableKeys: string[] | Array<keyof FiltersType> = ["value"].concat(queryKeys);

export const initialQueryParams: QueryParams = {
  limit: 10,
  offset: 0,
};

interface DeletingMessageType {
  [notificationType: string]: NotificationProps;
}

export const deletingMessage: DeletingMessageType = {
  confirm: {
    title: "Delete list type?",
    message:
      "If the list type contains emails, all emails will be deleted along with the list type. Proceed?",
  },
  success: {
    title: "Suppression list type deleted",
    message: "Successfully deleted suppression list type",
  },
  error: {
    title: "Deleting error",
    message: "Cannot delete item",
  },
};
