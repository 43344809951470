import { FC, useState } from "react";
import { FormikHelpers } from "formik";
import {
  useAutocomplete,
  Form,
  Button,
  Modal,
  MessageField,
  FormButtons,
  FormField,
  useFileParser,
} from "@epcnetwork/core-ui-kit";

import { filterDatabasesPipelines } from "utils";
import { FormField as LocalFormField } from "components";
import { getDatabasesNames } from "api";
import { MatchFileItem, Values } from "../match.types";
import { validationSchema } from "../match.constants";

import styles from "../match.module.css";

type MatchModalFormProps = {
  file: MatchFileItem;
  setClose: VoidFunction;
  onSubmitClick: (fileValues: MatchFileItem) => void;
};

const MatchModalForm: FC<MatchModalFormProps> = ({ file, setClose, onSubmitClick }) => {
  const { loading, parsedFile, error } = useFileParser(file.originalFile);

  const [initialValues] = useState<Values>({
    headerValues: {
      hasHeaders: file.data.hasHeaders,
      email: file.data.email,
      headers: file.data.headers,
    },
    databases: file.data.databases,
    categories: file.data.categories,
    listTypes: file.data.listTypes,
    isGlobal: file.data.isGlobal,
  });

  const databasesAutocomplete = useAutocomplete(getDatabasesNames, "name", "name");
  databasesAutocomplete.fetchOptions = filterDatabasesPipelines(databasesAutocomplete.fetchOptions);

  const onSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    onSubmitClick({
      ...file,
      isTuned: true,
      data: {
        ...file.data,
        ...values.headerValues,
        databases: values.databases,
        listTypes: values.listTypes,
        categories: values.categories,
        isGlobal: values.isGlobal,
      },
    });
    setSubmitting(false);
  };

  return (
    <Modal isOpen={Boolean(file)} setClose={setClose} contentClassName={styles.modal}>
      <h2>Set values {file.originalFile.name}</h2>
      <MessageField message={error} />
      <Form
        initialValues={initialValues}
        enableReinitialize
        onSubmit={onSubmit}
        loading={loading}
        validationSchema={validationSchema}
      >
        <FormField
          type="select"
          name="databases"
          label="Databases"
          placeholder="Databases"
          asyncOptions={databasesAutocomplete}
          isMulti
          canSelectAll
        />
        <LocalFormField
          type="file-table"
          name="headerValues"
          label="Select Email column"
          parsedFile={parsedFile}
          loading={loading}
        />
        <FormButtons>
          <Button appearance="secondary" onClick={setClose}>
            Cancel
          </Button>
          <Button type="submit">Set values</Button>
        </FormButtons>
      </Form>
    </Modal>
  );
};

export { MatchModalForm };
