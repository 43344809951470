import { useLocation, useHistory } from "react-router-dom";
import { memo, useMemo } from "react";
import cn from "classnames";
import { Button, Tooltip } from "@epcnetwork/core-ui-kit";

import { getActiveRoute } from "utils";
import { Menu } from "./components/menu";

import userImg from "assets/images/user.svg";
import openedImg from "assets/images/navigation-menu-opened.svg";
import closedImg from "assets/images/navigation-menu-closed.svg";
import styles from "./navbar.module.css";

type NavbarProps = {
  toggleSidebar: () => void;
  isSidebarOpen: boolean;
};

const Navbar = memo<NavbarProps>(({ toggleSidebar, isSidebarOpen }) => {
  const history = useHistory();
  const location = useLocation();

  const activeRoute = useMemo(() => getActiveRoute(location), [location]);
  const menuIcon = isSidebarOpen ? closedImg : openedImg;

  const hamburgerStyles = cn(styles.hamburger, { [styles.open]: isSidebarOpen });

  return (
    <header className={styles.container} data-testid="navbar">
      <Button appearance="transparent" className={hamburgerStyles} onClick={toggleSidebar}>
        <img src={menuIcon} alt="" width={20} height={20} />
      </Button>
      <div className={styles.title}>
        {activeRoute.name} {isSidebarOpen}
      </div>
      <div className={styles.spacer} />
      <Tooltip
        triggerElement={
          <Button appearance="transparent" imageBtn>
            <img src={userImg} alt="" width={36} height={36} />
          </Button>
        }
        position="bottom-right"
        trigger="click"
      >
        <Menu history={history} />
      </Tooltip>
    </header>
  );
});

export { Navbar };
