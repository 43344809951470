import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FC, useEffect } from "react";
import { Loader } from "@epcnetwork/core-ui-kit";

import { resetUserStore } from "store";
import { LOGIN_PAGE } from "constants/routes.constants";
import { TOKEN_STORAGE_FIELD, REFRESH_TOKEN_STORAGE_FIELD } from "constants/auth.constants";

const Logout: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // remove tokens
    localStorage.removeItem(TOKEN_STORAGE_FIELD);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_FIELD);

    // reset redux
    dispatch(resetUserStore());

    // redirect to login
    history.push(LOGIN_PAGE.path);
  }, [dispatch, history]);

  return <Loader type="puff-loader" size={75} />;
};

export { Logout };
