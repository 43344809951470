import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { FormikHelpers } from "formik";
import { useDidMount, useSubmit, useQuery } from "@epcnetwork/core-ui-kit";

import { setToken } from "store";
import { DEFAULT_PAGE, FORGOT_PASSWORD_PAGE } from "constants/routes.constants";
import { TOKEN_STORAGE_FIELD, REFRESH_TOKEN_STORAGE_FIELD } from "constants/auth.constants";
import { login } from "api";
import { Values } from "./login.types";

interface Output {
  onSubmit: (values: Values, helpers: FormikHelpers<Values>) => Promise<void>;
  handleForgotPassword: VoidFunction;
  errorMessage: string;
  hasPassChanged: boolean;
}

const useLoginHook = (): Output => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { query, setQueryParams } = useQuery();

  const [hasPassChanged, setHasPassChanged] = useState(false);

  useDidMount(() => {
    setHasPassChanged(query.changed === "true");
    setQueryParams({});
  });

  const { onSubmit, onSubmitSuccess, error } = useSubmit(login);

  onSubmitSuccess((payload) => {
    dispatch(setToken(payload.token));
    localStorage.setItem(TOKEN_STORAGE_FIELD, payload.token);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_FIELD, payload.refreshToken);
    history.push(DEFAULT_PAGE.path);
  });

  const handleForgotPassword = () => history.push(FORGOT_PASSWORD_PAGE.path);

  const errorMessage = error?.message || "";

  return {
    onSubmit,
    handleForgotPassword,
    errorMessage,
    hasPassChanged,
  };
};

export { useLoginHook };
