import {
  object,
  string,
  array,
  number,
  AnySchema,
  TestContext,
  ValidationError,
  AnyObjectSchema,
  ArraySchema,
} from "yup";

import { minOneFieldText, requiredFieldText } from "constants/form.constants";

// Validations
export const optionalValidation =
  (condition: boolean, message: string) =>
  (_field: unknown, passSchema: AnySchema): AnySchema =>
    condition ? passSchema : passSchema.required(message);

export const passwordValidation = (
  value: string | undefined,
  testContext: TestContext,
): ValidationError | boolean => {
  const password = value || "";

  const minLength = 8;
  const isRequiredLength = password.length >= minLength;
  if (!isRequiredLength) {
    return testContext.createError({ message: "Password needs to include at least 8 characters" });
  }
  const hasLowerCase = password.match(/[a-z]+/);
  const hasUpperCase = password.match(/[A-Z]+/);
  if (!hasLowerCase || !hasUpperCase) {
    return testContext.createError({
      message:
        "Password needs to include both upper and lowercase alphabetic characters (e.g. A-Z, a-z)",
    });
  }
  const hasNumber = password.match(/[0-9]+/);
  if (!hasNumber) {
    return testContext.createError({
      message: "Password needs to include at least one numerical character (e.g. 0-9)",
    });
  }
  const hasSpecialCharacter = password.match(/[_\W]+/);
  if (!hasSpecialCharacter) {
    return testContext.createError({
      message: "Password needs to include at least one special character (e.g. ~!@#$%^&*()_-+=)",
    });
  }
  return true;
};

export const fileValidation = (requiredMessage = requiredFieldText): AnySchema => {
  return array()
    .of(object().typeError(requiredMessage).required(requiredMessage))
    .min(1, minOneFieldText)
    .typeError(requiredMessage)
    .required(requiredMessage);
};

export const selectValidation = (requiredMessage = requiredFieldText): AnySchema => {
  return string().min(1, minOneFieldText).typeError(requiredMessage).required(requiredMessage);
};

export const selectNumberValidation = (requiredMessage = requiredFieldText): AnySchema => {
  return number().typeError(requiredMessage).required(requiredMessage);
};

export const selectMultipleValidation = (
  requiredMessage = requiredFieldText,
): ArraySchema<AnySchema> => {
  return array()
    .of(string().required())
    .typeError(requiredMessage)
    .min(1, minOneFieldText)
    .required(requiredMessage);
};

export const headersValidation = (requiredMessage = requiredFieldText): ArraySchema<AnySchema> => {
  return array()
    .of(string().ensure())
    .min(1, minOneFieldText)
    .test("isFilled", requiredMessage, (value) => {
      return Boolean((value || []).find((header: string) => Boolean(header)));
    })
    .required(requiredMessage);
};

export const suppressionDatabaseValidation = (requiredMessage = requiredFieldText): AnySchema => {
  return array().when("databases", (databases) => {
    return databases.includes("suppression")
      ? array()
          .of(string().required())
          .typeError(requiredMessage)
          .min(1, minOneFieldText)
          .required(requiredMessage)
      : array().of(string()).notRequired();
  });
};

export const emailColumnValidation = (requiredMessage = requiredFieldText): AnyObjectSchema => {
  return object().shape({
    key: string().min(1, minOneFieldText).required(requiredMessage),
    index: number().min(0, minOneFieldText).required(requiredMessage),
  });
};
