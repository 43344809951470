import { useState, FC } from "react";
import { useFormikContext } from "formik";
import { FormField, useAutocomplete, SelectOption } from "@epcnetwork/core-ui-kit";

import { Hint } from "components";
import { getSuppressionCategories, getSuppressionListTypeSimple } from "api";
import { withoutCategoryOption, suppressionKindOptions } from "./list-type.constants";

import styles from "./list-type.module.css";

const ListTypeSection: FC = () => {
  const { setFieldValue } = useFormikContext();
  const [selectedKind, setSelectedKind] = useState("");
  const listTypeAutocomplete = useAutocomplete(getSuppressionListTypeSimple, "id", "name");
  const categoriesAutocomplete = useAutocomplete(getSuppressionCategories, "id", "name");

  const listTypeMapOptions = (
    options: (typeof listTypeAutocomplete)["fetchOptions"],
  ): (typeof listTypeAutocomplete)["fetchOptions"] => {
    const kind = selectedKind ? selectedKind === "true" : "";

    return options.filter((opt) => (selectedKind ? Boolean(opt.isGlobal) === kind : options));
  };

  const categoriesMapOptions = (
    options: (typeof categoriesAutocomplete)["fetchOptions"],
  ): (typeof categoriesAutocomplete)["fetchOptions"] => {
    return options.concat(withoutCategoryOption);
  };

  const handleChangeListKind = (option: SelectOption) => {
    if (option) {
      setSelectedKind(option.value as string);
      setFieldValue("listTypes", []);
      return;
    }
    setSelectedKind("");
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.verticalLine} />
        <div>
          <FormField
            type="select"
            name="isGlobal"
            label="Kind of list type"
            placeholder="Select from the list"
            options={suppressionKindOptions}
            onChange={handleChangeListKind}
          />
          <FormField
            type="select"
            name="listTypes"
            label="List Type"
            placeholder="Select from the list"
            asyncOptions={{
              ...listTypeAutocomplete,
              fetchOptions: listTypeMapOptions(listTypeAutocomplete.fetchOptions),
            }}
            isMulti
            isSearchable
            required
          />
          <FormField
            type="select"
            name="categories"
            label="Category"
            placeholder="Select from the list"
            asyncOptions={{
              ...categoriesAutocomplete,
              fetchOptions: categoriesMapOptions(categoriesAutocomplete.fetchOptions),
            }}
            isMulti
          />
        </div>
      </div>
      <Hint className={styles.hint}>
        Note: Only Suppression DataBase has additional options like List Type and Suppression
        category
      </Hint>
    </>
  );
};

export { ListTypeSection };
