import { memo } from "react";
import { useField, useFormikContext } from "formik";

import { FormFileTableHeaders } from "types";
import { FileTable, FileTableProps } from "components/form/file-table/file-table";

type Props = {
  name: string;
  onFieldChange?: (value: FormFileTableHeaders) => void;
  value?: FormFileTableHeaders;
};

export type FileTableFormikProps = Props & Omit<FileTableProps, "value" | "onChange">;

const FileTableFormik = memo(
  ({ value, onFieldChange = () => undefined, ...rest }: FileTableFormikProps) => {
    const [field, meta, helpers] = useField<FormFileTableHeaders>({ name: rest.name });
    const { submitCount } = useFormikContext();
    const isTouched = Boolean(submitCount) || meta.touched;

    const { setValue } = helpers;
    const { error } = meta;

    const errorMessage =
      rest?.error || (isTouched && getFormError(error as FileTableProps["value"])) || "";
    const status = errorMessage ? "error" : undefined;

    function handleSetValue(value: FormFileTableHeaders) {
      onFieldChange(value);
      setValue(value);
    }

    return (
      <FileTable
        {...field}
        {...rest}
        error={errorMessage}
        status={status}
        onChange={handleSetValue}
      />
    );
  },
);

export { FileTableFormik };

const getFormError = (formikError: FileTableProps["value"]) => {
  let error = "";
  if (formikError?.email) {
    error = String(formikError.email.index || formikError.email.key);
  } else if (formikError?.headers) {
    error = String(formikError.headers);
  }
  return error;
};
