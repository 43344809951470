import { FileQueueItem } from "@epcnetwork/core-ui-kit";

import { FormFile, FormFileTableHeaders } from "types";

export enum HashTypesEnum {
  md5 = "md5",
  sha256 = "sha256",
}

export type ReverseFileData = {
  fileName: string;
  databases: string[];
  email: { key: string; index: number };
  headers: string[];
  hasHeaders: boolean;
  hashType: HashTypesEnum;
  md5: { key: string; index: number };
  listTypes?: string[];
  categories?: string[];
  isGlobal?: boolean;
};

export type ReverseFileItem = FileQueueItem<ReverseFileData>;

export type PipelineReverseData = {
  file: FormFile;
  fileInfo: ReverseFileData;
};

export type Values = {
  headerValues: Omit<FormFileTableHeaders, "email">;
  databases: string[];
  hashType: HashTypesEnum;
  listTypes?: string[];
  categories?: string[];
  isGlobal?: boolean;
};
