import { ref, object, string, boolean, AnySchema } from "yup";

import { optionalValidation, passwordValidation } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { Values } from "./users-form.types";

export const initialData: Values = {
  email: "",
  password: "",
  passwordConfirm: "",
  userGroup: "",
  roles: "",
  isEdit: false,
};

export const roles = [
  { value: "ADMIN", label: "Admin" },
  { value: "SELF", label: "Self" },
];

export const validationSchema = (id: string | null): AnySchema =>
  object({
    isEdit: boolean(),
    email: string().email("Email is not valid").required(requiredFieldText),
    password: string().when("isEdit", {
      is: (val: boolean) => !val,
      then: string()
        .min(8, "Password needs to include at least 8 characters")
        .max(30, "Password is too long")
        .test("password validation", "", passwordValidation),
      otherwise: string(),
    }),
    confirmPassword: string()
      .oneOf([ref("password"), null], "Passwords must match")
      .when(
        "$passwordConfirm",
        optionalValidation(Boolean(id), "Password confirmation is required"),
      ),
    userGroup: string().required(requiredFieldText),
    roles: string()
      .when("$passwordConfirm", optionalValidation(Boolean(id), requiredFieldText))
      .required(requiredFieldText),
  });
