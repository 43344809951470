import { useHistory } from "react-router-dom";
import { FC, useState } from "react";
import cn from "classnames";
import {
  Button,
  CheckOption,
  Container,
  List,
  Modal,
  Table,
  Tooltip,
  useDevice,
  useFetch,
  useFilters,
  usePagination,
} from "@epcnetwork/core-ui-kit";

import { SuppressionSettingsFormPage } from "pages/suppression-settings/form/suppression-settings-form.page";
import { SuppressionModel } from "models";
import { usePayload } from "hooks";
import { SUPPRESSION_IMPORT_PAGE } from "constants/routes.constants";
import { searchMinLength } from "constants/form.constants";
import { DeleteErrorModal, Toolbar } from "components";
import { getSuppressions } from "api";
import { DeleteModal } from "../form/components/delete-modal/delete-modal";
import { EmailsSuppressionFormPage } from "../form";
import { Row } from "./table-row/table-row";
import { QueryParams } from "./suppression-list.types";
import { initialQueryParams, nonClearableKeys, tableColumns } from "./suppression-list.constants";
import { ListFilters } from "./list-filters/list-filters";
import { ExportModal } from "./export-modal/export-modal";

import suppressionImg from "assets/images/suppression-list.svg";
import saveFileImg from "assets/images/save-file.svg";
import addImg from "assets/images/add-with-background.svg";
import styles from "./suppression-list.module.css";

const SuppressionListPage: FC = () => {
  const history = useHistory();
  const { isMobileDevice } = useDevice();
  const [isListTypeModalOpen, setIsListTypeModalOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editId, setEditId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [email, setEmail] = useState("");
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const { queryString, searchValue, setSearch, query } = useFilters<QueryParams>({
    initialState: initialQueryParams,
    searchOptions: { searchKey: "value", searchMinLength },
    nonClearableKeys,
  });

  const handleSetEmail = (value: string) => {
    setEmail(value);
  };

  const dependencies = [queryString];
  const apiResponse = useFetch(getSuppressions.setQueryParams(queryString), { dependencies });
  const pagination = usePagination({ listPayload: apiResponse.payload });
  const { payload, list, refresh } = usePayload(apiResponse);

  const handleEditSuppression = (id: string) => {
    setEditId(id);
    setModalOpen(true);
  };

  const handleDeleteSuppression = (id: string) => {
    setDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  const handleImport = () => {
    history.push(SUPPRESSION_IMPORT_PAGE.path);
  };

  const openModal = () => {
    setIsTooltipOpen(false);
    setModalOpen(true);
  };

  const closeModal = () => {
    setEditId("");
    setModalOpen(false);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const closeListTypeModal = () => {
    setIsListTypeModalOpen(false);
  };

  const closeExportModal = () => {
    setIsExportModalOpen(false);
  };

  const openExportModal = () => {
    setIsExportModalOpen(true);
  };

  const handleSuppressionList = () => setIsListTypeModalOpen(true);

  const handleTooltipOpen = () => setIsTooltipOpen(true);

  const handleItemChange = (values: SuppressionModel) => {
    const isExistElement = list.some((el) => el.id === values.id);
    const filteredList = list.filter((listElem) => listElem.id !== values.id);
    const newData: List<SuppressionModel> = {
      ...payload,
      data: [values, ...filteredList],
      total: isExistElement ? Number(payload.total) : Number(payload.total) + 1,
    };
    apiResponse.actions.setData(newData);
    setModalOpen(false);
    setEditId("");
  };

  return (
    <Container>
      <Toolbar searchValue={searchValue} setSearch={setSearch} filters={<ListFilters />}>
        <div className={styles.buttons}>
          <Button appearance="text" onClick={openExportModal} className={styles.btn}>
            <img src={saveFileImg} alt="" width={20} height={20} />
            Export
          </Button>
          <Button appearance="text" onClick={handleSuppressionList} className={styles.btn}>
            <img src={suppressionImg} alt="" width={20} height={20} />
            New List Type
          </Button>
          <Tooltip
            triggerElement={
              <Button className={styles.tooltipButtons} onClick={handleTooltipOpen}>
                <img src={addImg} alt="" width={16} height={16} />
                Suppress New Item(s)
              </Button>
            }
            position="bottom"
            trigger="click"
            stretchWidth
            isOpen={isTooltipOpen}
            isManualControl
            outsideClickOption={CheckOption.contains}
            onCanShowChange={setIsTooltipOpen}
          >
            <Button appearance="text" onClick={handleImport} className={styles.btnMenu}>
              Upload From File
            </Button>
            <Button onClick={openModal} appearance="text" className={styles.btnMenu}>
              Create single item
            </Button>
          </Tooltip>
        </div>
      </Toolbar>
      <Table
        {...apiResponse}
        error={apiResponse.error?.message}
        list={list}
        columns={tableColumns}
        pagination={{ ...pagination, className: cn({ [styles.pagination]: isMobileDevice }) }}
        row={(item) => (
          <Row
            key={item.id}
            item={item}
            refresh={refresh}
            handleEditSuppression={handleEditSuppression}
            handleDeleteSuppression={handleDeleteSuppression}
            handleSetEmail={handleSetEmail}
          />
        )}
      />
      <Modal isOpen={isModalOpen} setClose={closeModal} contentClassName={styles.modal}>
        <EmailsSuppressionFormPage
          closeModal={closeModal}
          handleItemChange={handleItemChange}
          editId={editId}
        />
      </Modal>

      <Modal
        isOpen={isListTypeModalOpen}
        setClose={closeListTypeModal}
        contentClassName={styles.listTypeModal}
      >
        <SuppressionSettingsFormPage closeModal={closeListTypeModal} editId="" />
      </Modal>

      <Modal
        isOpen={isExportModalOpen}
        setClose={closeExportModal}
        contentClassName={styles.exportModal}
      >
        <ExportModal closeModal={closeExportModal} appliedFilters={query} />
      </Modal>
      {deleteId && email ? (
        <Modal isOpen={isDeleteModalOpen} setClose={closeDeleteModal} size="small">
          <DeleteModal
            closeModal={closeDeleteModal}
            deleteId={deleteId}
            refresh={refresh}
            email={email}
          />
        </Modal>
      ) : (
        <DeleteErrorModal closeModal={closeDeleteModal} isOpen={isDeleteModalOpen} value={email} />
      )}
    </Container>
  );
};
export { SuppressionListPage };
