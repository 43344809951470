import { object, string, SchemaOf } from "yup";
import { SelectOption } from "@epcnetwork/core-ui-kit";

import { selectMultipleValidation } from "utils";
import { SuppressionTypeModel } from "models";
import { requiredFieldText, searchMinLengthFieldText } from "constants/form.constants";
import { SuppressionRequiredValues, SuppressionValues } from "./suppression-form.types";

export const suppressionTypes: SuppressionTypeModel[] = [
  {
    value: "Email",
    label: "Email",
  },
  {
    value: "Domain",
    label: "Domain",
  },
  {
    value: "Keyword",
    label: "Keyword",
  },
];

export const suppressionFilterTypes: SuppressionTypeModel[] = [
  {
    value: "Email",
    label: "Email",
  },
  {
    value: "Domain",
    label: "Domain",
  },
  {
    value: "Keyword",
    label: "Keyword",
  },
  {
    value: "Regex",
    label: "Regex",
  },
];

export const suppressionKindOptions: SelectOption[] = [
  {
    value: "false",
    label: "Local",
  },
  {
    value: "true",
    label: "Global",
  },
];

export const initialValues: SuppressionValues = {
  value: "",
  category: [],
  cleaningServiceStatus: "",
  esp: [],
  listType: [],
  type: "",
};

export const validationSchema: SchemaOf<SuppressionRequiredValues> = object().shape({
  value: string().min(3, searchMinLengthFieldText).required(requiredFieldText),
  type: string().required(requiredFieldText),
  listType: selectMultipleValidation(),
});
