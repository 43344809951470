import { useHistory } from "react-router-dom";
import { FC, useCallback, useRef, useState } from "react";
import cn from "classnames";
import {
  Button,
  Container,
  useWillUnmount,
  useDidUpdate,
  useFileQueue,
  Nullable,
  DropAcceptedFunc,
  notification,
  FileItem,
  FileUpload,
} from "@epcnetwork/core-ui-kit";

import {
  getAcceptedFilesText,
  getFileUuidName,
  getPipelineRoute,
  getSupportedFormats,
  onFileRedirect,
} from "utils";
import { PIPELINES_LIST_PAGE } from "constants/routes.constants";
import { postISPDomainSplitterFile } from "api";
import { PipelineHeaderSection } from "../components/pipeline-header-section/pipeline-header-section";
import { PipelineISPDomainSplitterData, IspSplitterFileItem } from "./isp-splitter.types";
import { supportedFormats, getInitialData } from "./isp-splitter.constants";
import { IspSplitterModalForm } from "./components/isp-splitter-form-modal";

import styles from "./isp-splitter.module.css";

const IspSplitterPage: FC = () => {
  const componentIsMounted = useRef(true);
  useWillUnmount(() => (componentIsMounted.current = false));
  const history = useHistory();

  const pipeline = getPipelineRoute("ISP Domain Splitter");

  const {
    files,
    createInitialFile,
    addFiles,
    updateFiles,
    removeFiles,
    clearEntity,
    getItem,
    isEntityInConfiguration,
    isEntityFinished,
    hasAllConfigured,
    submitOneByOne,
    hasAllSuccessFiles,
  } = useFileQueue<IspSplitterFileItem, PipelineISPDomainSplitterData>(
    pipeline.title,
    postISPDomainSplitterFile,
  );

  useDidUpdate(() => {
    if (!isEntityFinished) return;

    handleRedirect();
  }, [isEntityFinished]);

  const [editedFile, setEditedFile] = useState<Nullable<IspSplitterFileItem>>(null);

  const handleAcceptedDrop: DropAcceptedFunc = useCallback(
    (acceptedFiles) => {
      addFiles(
        acceptedFiles.map((file) => createInitialFile(file, { data: getInitialData(file) })),
      );
    },
    [addFiles, createInitialFile],
  );

  const handleClearClick = () => {
    notification.confirm(
      "Delete files",
      `Are you sure you want to delete all ${files.length} file(s)?`,
      {
        onOk: clearEntity,
        icon: "delete",
      },
    );
  };

  const handleConfigureItem = useCallback(
    (id: string) => {
      const item = getItem(id);
      if (!item) return;
      setEditedFile(item);
    },
    [getItem],
  );

  const handleModalSubmit = useCallback(
    ({ id, ...rest }: IspSplitterFileItem) => {
      updateFiles({ id, file: rest });
      setEditedFile(null);
    },
    [updateFiles],
  );

  const handleCloseModal = useCallback(() => setEditedFile(null), []);

  const handleSubmit = () => {
    const data = files.map((file) => {
      const formData = new FormData();
      formData.append("fileInfo", JSON.stringify(file.data));
      formData.append("file", file.originalFile, getFileUuidName(file));

      return {
        id: file.id,
        data: formData,
      };
    });

    submitOneByOne(data);
  };

  const handleRedirect = () => {
    onFileRedirect({ files, clearEntity, history });
  };

  const handleRedirectToPipelines = () => {
    history.push(PIPELINES_LIST_PAGE.path);
  };

  return (
    <Container>
      <div className={styles.container}>
        <PipelineHeaderSection pipeline={pipeline} />

        <div className={styles.form}>
          <FileUpload
            className={cn(styles.dropZone, { [styles.uploadZone]: files.length })}
            uploadedFilesLength={files.length}
            subtitle={getAcceptedFilesText(getSupportedFormats(supportedFormats))}
            accept={supportedFormats}
            onDropAccepted={handleAcceptedDrop}
            exceedFilesOption="splice-with-error"
            disabled={!isEntityInConfiguration}
            preventDropOnDocument
            multiple
          />
          <div className={styles.fileList}>
            {files.map(({ id, originalFile, data, ...rest }) => (
              <FileItem
                {...rest}
                key={id}
                id={id}
                file={originalFile}
                onCrossClick={removeFiles}
                onSetValuesClick={handleConfigureItem}
                onEditValuesClick={handleConfigureItem}
              >
                {data.email.key && (
                  <div className={styles.additionalInfo}>
                    <span>Selected column:</span> {data.email.key}
                  </div>
                )}
              </FileItem>
            ))}
          </div>
          {editedFile && (
            <IspSplitterModalForm
              file={editedFile}
              setClose={handleCloseModal}
              onSubmitClick={handleModalSubmit}
            />
          )}
          <div className={styles.buttons}>
            <Button
              appearance="secondary"
              onClick={!files.length ? handleRedirectToPipelines : handleClearClick}
              disabled={isEntityFinished && !hasAllSuccessFiles}
            >
              Cancel
            </Button>
            {!isEntityFinished && (
              <Button
                onClick={handleSubmit}
                disabled={!hasAllConfigured || !files.length}
                loading={!isEntityInConfiguration}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export { IspSplitterPage };
