import { FC, useState } from "react";
import { format } from "date-fns";
import {
  TableRow,
  TableCell,
  notification,
  MenuButton,
  Menu,
  useCall,
} from "@epcnetwork/core-ui-kit";

import { UserModel } from "models";
import { deleteUser } from "api";

import lock from "assets/images/lock.svg";
import clockImg from "assets/images/clock.svg";
import calendarImg from "assets/images/calendar.svg";
import styles from "./table-row.module.css";

type RowProps = {
  item: UserModel;
  handleEdit: (id: string, isRoleModal?: boolean) => void;
};

const Row: FC<RowProps> = ({ item, handleEdit }) => {
  const [isDeleted, setDeleted] = useState(false);

  const { submit, onCallSuccess, onCallError } = useCall(deleteUser);

  onCallSuccess(() => {
    notification.success("User deleted", "Successfully deleted user");
  });

  onCallError(() => {
    setDeleted(false);
    notification.error("Deleting error", "Cannot delete user");
  });

  const handleRemoveItem = (id: string) => {
    notification.confirm("Deleting user?", "Are you sure?", {
      onOk: () => {
        setDeleted(true);
        submit({ params: { userId: id } });
      },
      okText: "Delete",
      icon: "delete",
    });
  };

  const date = new Date(item.createdAt);

  if (isDeleted) {
    return null;
  }

  return (
    <TableRow id={item.userId}>
      <TableCell>{item.email}</TableCell>
      <TableCell>{item.roles[0]}</TableCell>
      <TableCell>{item.userGroup?.name || "-"}</TableCell>
      <TableCell>
        <div className={styles.date}>
          <img src={calendarImg} alt="" width="11" height="11" />
          {format(date, "EEE, MMM dd, yyyy")}
          <img src={clockImg} alt="" width="11" height="11" />
          {format(date, "hh:mm aaa")}
        </div>
      </TableCell>
      <TableCell>
        <Menu>
          <MenuButton onClick={() => handleEdit(item.userId)} icon="edit">
            Edit
          </MenuButton>
          <MenuButton onClick={() => handleEdit(item.userId, true)}>
            <img src={lock} alt="" width={13} height={16} className={styles.lockIcon} />
            Edit Permissions
          </MenuButton>
          <MenuButton
            appearance="delete"
            icon="delete"
            onClick={() => handleRemoveItem(item.userId)}
          >
            Delete
          </MenuButton>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export { Row };
