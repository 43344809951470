import { boolean, object, SchemaOf } from "yup";

import { emailColumnValidation, headersValidation, sanitizeFileName } from "utils";
import { requiredFieldText } from "constants/form.constants";
import { csvFormat, txtFormat } from "constants/file.constants";
import { Values, IspSplitterFileData } from "./isp-splitter.types";

export const supportedFormats = [csvFormat, txtFormat].flat();

export const getInitialData = (item: File): IspSplitterFileData => ({
  fileName: sanitizeFileName(item.name),
  headers: [],
  hasHeaders: false,
  email: { key: "", index: -1 },
});

export const validationSchema: SchemaOf<Values> = object({
  headerValues: object({
    email: emailColumnValidation(),
    headers: headersValidation(),
    hasHeaders: boolean().required(requiredFieldText),
  }).required(requiredFieldText),
});
