import { FC, useMemo, useState } from "react";
import { matchSorter } from "match-sorter";
import {
  useFilters,
  ArrayElement,
  Modal,
  Button,
  useFetch,
  Table,
  Container,
} from "@epcnetwork/core-ui-kit";

import { FiltersType } from "types";
import { UserModel } from "models";
import { Toolbar } from "components";
import { getUsers } from "api";
import { UsersForm, RolesForm } from "../forms";
import { TABLE_COLUMNS, filtersOptions } from "./users-list.constants";
import { Row } from "./table-row/table-row";

import addImg from "assets/images/add-with-background.svg";
import styles from "./users-list.module.css";

const UsersListPage: FC = () => {
  const { searchValue, setSearch } = useFilters<FiltersType>(filtersOptions);

  const [editId, setEditId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const { payload, loading, refresh, error, actions } = useFetch(getUsers);

  const handleAdd = () => {
    setEditId(null);
    setIsModalOpen(true);
  };

  const handleEdit = (id: string, isRoleModal?: boolean) => {
    setEditId(id);
    if (isRoleModal) {
      setIsEditModalOpen(true);
      return;
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsEditModalOpen(false);
    setEditId(null);
  };

  const handleItemChange = (values: UserModel) => {
    const filteredList = payload?.filter((listElem) => listElem.userId !== values.userId) || [];
    const newData = [values, ...filteredList];
    actions.setData(newData);
    setIsModalOpen(false);
  };

  const filteredList = useMemo(() => {
    let list: NonNullable<typeof payload> = payload || [];

    if (searchValue) {
      const keys: (keyof ArrayElement<NonNullable<typeof payload>>)[] = ["email"];
      list = matchSorter(list, searchValue, { keys });
    }

    return list;
  }, [searchValue, payload]);

  return (
    <Container>
      <Toolbar searchValue={searchValue} setSearch={setSearch}>
        <Button className={styles.addBtn} onClick={handleAdd}>
          <img src={addImg} alt="" width={16} height={16} />
          <span className={styles.addBtnText}> Add User</span>
        </Button>
      </Toolbar>
      <div className={styles.wrapper}>
        <Table
          columns={TABLE_COLUMNS}
          list={filteredList}
          error={error?.message}
          loading={loading}
          refresh={refresh}
          isTabTable
          row={(item: ArrayElement<NonNullable<typeof payload>>) => (
            <Row key={item.userId} item={item} handleEdit={handleEdit} />
          )}
        />
        <Modal isOpen={isModalOpen} setClose={handleCloseModal}>
          <UsersForm
            id={editId}
            handleItemChange={handleItemChange}
            handleCloseModal={handleCloseModal}
          />
        </Modal>
        <Modal isOpen={isEditModalOpen} setClose={handleCloseModal}>
          <RolesForm
            id={editId}
            handleCloseModal={handleCloseModal}
            handleItemChange={handleItemChange}
          />
        </Modal>
      </div>
    </Container>
  );
};

export { UsersListPage };
